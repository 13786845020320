
import * as api from "../../api";
import {
  UPDATE_AU_COLOR,
  UPDATE_AU_FONT_SIZE,
  UPDATE_AU_NOTIFICATION,
  GET_AU_SETTING,
} from "../../constants/actionTypes";

// export const factoryNameAu = (factoryName) => async (dispatch) => {
//   try {
//     const { data } = await api.factoryNameAu(factoryName);
//     dispatch({ type: UPDATE_FACTORY_NAME_AU, payload: data });
//     return data;
//   } catch (error) {
//     console.log(error);
//     throw error;
//   }
// };

// export const updateAuLogo = (formData) => async (dispatch) => {
//   try {
//     const { data } = await api.updateAuLogo(formData);
//     dispatch({ type: UPDATE_AU_LOGO, payload: data });
//     return data;
//   } catch (error) {
//     console.log(error);
//     throw error;
//   }
// };

export const updateAuFontSize = (fontSize) => async (dispatch) => {
  try {
    const { data } = await api.updateAuFontSize(fontSize);
    dispatch({ type: UPDATE_AU_FONT_SIZE, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateAuNotification = (notification) => async (dispatch) => {
  try {
    const { data } = await api.updateAuNotification(notification);
    dispatch({ type: UPDATE_AU_NOTIFICATION, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateAuColor = (colorInfo) => async (dispatch) => {
  try {
    const { data } = await api.updateAuColor(colorInfo);
    dispatch({ type: UPDATE_AU_COLOR, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAuSetting = () => async (dispatch) => {
  try {
    const { data } = await api.getAuSetting();
    dispatch({ type: GET_AU_SETTING, payload: data });
    return data;
  } catch (error) {
    console.log(error);
  }
};
