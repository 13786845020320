import React from "react";
import { Breadcrumb, Tabs } from "antd";
import FontSize from "./FontSize";
import Color from "./Color";
import Notification from "./Notification";

const { TabPane } = Tabs;

const Settings = () => {
  return (
    <div>
      <div className="flex justify-between items-center pl-5 pr-5 pt-5">
        <h2 className="text-lg font-semibold  text-gray-800 font-poppins">
          Settings
        </h2>
        <Breadcrumb className="font-poppins">
          <Breadcrumb.Item>
            <a href="/assistant/dashboard">Home </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Settings</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Font Size" key="1">
            <FontSize />
          </TabPane>
          <TabPane tab="Color" key="2">
            <Color />
          </TabPane>
          <TabPane tab="Notification" key="3">
            <Notification />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Settings;
