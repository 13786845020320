import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spin } from "antd";
import { Breadcrumb } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAuAssignOrder } from "../../../actions/auDashboard/auDashboard";

const cardStyle = {
  marginBottom: "10px",
  padding: "24px",
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const order = useSelector((state) => state.auDashboard.order);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([dispatch(getAuAssignOrder())]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <>
      <div className="flex justify-between items-center pl-5 pr-5 pt-5">
        <h2 className="text-lg font-semibold  text-gray-800 font-poppins">
          Dashboard
        </h2>
        <Breadcrumb className="font-poppins">
          <Breadcrumb.Item>
            <a href="/assistant/dashboard">Home </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        {loading ? (
          <div className="flex items-center flex-col">
            <Spin />
          </div>
        ) : (
          <div style={{ padding: "20px" }}>
            <h3>Hii Welcome back</h3>
            <Row gutter={16} justify="start">
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Assign Order"
                  bordered={false}
                  style={{ background: "#fff2cd", ...cardStyle }}
                >
                  {order?.assignedOrdersCount}
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="New Users"
                  bordered={false}
                  style={{ background: "#feede7", ...cardStyle }}
                >
                  5
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Item Orders"
                  bordered={false}
                  style={{ background: "#f9d9f9", ...cardStyle }}
                >
                  4
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Bug Reports"
                  bordered={false}
                  style={{ background: "#fff2cd", ...cardStyle }}
                >
                  4
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
