import * as api from "../../api";
import {ASSIGN_ORDER} from "../../constants/actionTypes";


export const assignOrder = (orderInfo) => async (dispatch) => {
    try {
        const { data } = await api.assignOrder(orderInfo);
        dispatch({ type: ASSIGN_ORDER, payload: data });
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};