import React, { useState } from "react";
import { Form, Row, Col, Input, Button, message } from "antd";
import { updateLogo } from "../../actions/settings/settings";
import { useDispatch } from "react-redux";

const Logo = () => {
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(false);
  const [image, setImage] = useState(null);
  const [form] = Form.useForm();

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const fileName = event.target.files[0];
      setImage(fileName);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("logo", image);
      const res = await dispatch(updateLogo(formData));
      if (res.success) {
        message.success(res.message);
        form.resetFields();
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      message.error(error.response.data.message);
    }finally{
      setLoading(false);
    }
  };
  return (
    <Form
      form={form}
      onFinish={onFinish}
      name="myForm"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Logo"
            name="logo"
            rules={[{ required: true, message: "Please select logo!" }]}
          >
            <Input
              type="file"
              onChange={onImageChange}
              className="filetype custom-input"
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button className="default-btn" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Logo;
