import React, { useState, useEffect } from "react";
import { Breadcrumb, Tabs, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import NewOrderList from "./newOrderList/NewOrderList";
import OrderLists from "./orderLists/OrderLists";
import { getProduct } from "../../actions/master/product";
import { getStatus } from "../../actions/master/status";
import { getLocation } from "../../actions/master/location";
import { getDesigner } from "../../actions/master/designer";
import { getCarcass } from "../../actions/master/carcass";
import { getShutter } from "../../actions/master/shutter";
import { getSource } from "../../actions/master/source";
import { getSalesPerson } from "../../actions/master/salesPerson";
import { getFinalSiteSurveyor } from "../../actions/master/finalSiteSurveyor";
import { getFactoryEngineer } from "../../actions/master/factoryEngineer";

const { TabPane } = Tabs;

const OrderList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const product = useSelector((state) => state.product.product);
  const status = useSelector((state) => state.status.status);
  const location = useSelector((state) => state.location.location);
  const designer = useSelector((state) => state.designer.designer);
  const carcass = useSelector((state) => state.carcass.carcass);
  const shutter = useSelector((state) => state.shutter.shutter);
  const source = useSelector((state) => state.source.source);
  const salesPerson = useSelector((state) => state.salesPerson.salesPerson);
  const siteSurveyor = useSelector(
    (state) => state.finalSiteSurveyor.finalSiteSurveyor
  );
  const factoryEngineer = useSelector(
    (state) => state.factoryEngineer.factoryEngineer
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          dispatch(getProduct()),
          dispatch(getStatus()),
          dispatch(getLocation()),
          dispatch(getDesigner()),
          dispatch(getCarcass()),
          dispatch(getShutter()),
          dispatch(getFinalSiteSurveyor()),
          dispatch(getSource()),
          dispatch(getSalesPerson()),
          dispatch(getFactoryEngineer()),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);
  return (
    <div>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Orderlist</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            {" "}
            <a href="/factory">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Orderlist</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        <div>
          <Spin spinning={loading}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="New Order List" key="1">
                <NewOrderList
                  location={location}
                  status={status}
                  product={product}
                  shutter={shutter}
                  carcass={carcass}
                  designer={designer}
                  source={source}
                  siteSurveyor={siteSurveyor}
                  salesPerson={salesPerson}
                  factoryEngineer={factoryEngineer}
                />
              </TabPane>
              <TabPane tab="Order Lists" key="2">
                <OrderLists />
              </TabPane>
            </Tabs>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
