import React, { useEffect, useState } from "react";
import { Table, Spin, Breadcrumb, Card,Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { getAssistantAssignOrder } from "../../../actions/assistantAssignOrder/assistantAssignOrder";


const AssignOrder = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const assign = useSelector((state) => state.assignOrderReducer.assignOrder);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await dispatch(getAssistantAssignOrder());
      } catch (error) {
        console.log(error.response.data.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    // Check if assign.assignedOrders is an array and not empty
    if (Array.isArray(assign.assignedOrders) && assign.assignedOrders.length > 0) {
      // Concatenate all orders from all assigned orders
      const allOrders = assign.assignedOrders.reduce((acc, assignedOrder) => {
        if (assignedOrder.orders && Array.isArray(assignedOrder.orders)) {
          acc = acc.concat(assignedOrder.orders);
        }
        return acc;
      }, []);

      setOrder(allOrders);
    } else {
      setOrder([]);
    }
  }, [assign]);

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
      render: (text, record) => {
        return record.location.pincode;
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (location) => location.name,
    },
    {
      title: "Customer Cordinator Name",
      dataIndex: "customerCordinatorName",
      key: "customerCordinatorName",
      render: (text, record) => {
        return record.customerCordinator.name;
      },
    },
    {
      title: "Customer Cordinator Number",
      dataIndex: "customerCordinatorNumber",
      key: "customerCordinatorNumber",
      render: (text, record) => {
        return record.customerCordinator.number;
      },
    },
    {
      title: "Source Cordinator",
      dataIndex: "sourceCordinator",
      key: "sourceCordinator",
      render: (text, record) => {
        return record.sourceCordinator.name;
      },
    },
    {
      title: "Source Cordinator Number",
      dataIndex: "sourceCordinatorNumber",
      key: "sourceCordinatorNumber",
      render: (text, record) => {
        return record.sourceCordinator.number;
      },
    },
    {
      title: "Factory Cordinator",
      dataIndex: "factoryCordinator",
      key: "factoryCordinator",
      render: (text, record) => {
        return record.factoryCordinator.name;
      },
    },
    {
      title: "Factory Cordinator Number",
      dataIndex: "factoryCordinatorNumber",
      key: "factoryCordinatorNumber",
      render: (text, record) => {
        return record.factoryCordinator.number;
      },
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (product) => product.product,
    },
    {
      title: "Product Id",
      dataIndex: "productId",
      key: "productId",
    },
    {
      title: "Sale Value",
      dataIndex: "saleValue",
      key: "saleValue",
    },
    {
      title: "Material Value",
      dataIndex: "materialValue",
      key: "materialValue",
    },
    {
      title: "Face Area",
      dataIndex: "faceArea",
      key: "faceArea",
    },
    {
      title: "Target Start Date",
      dataIndex: "targetStartDate",
      key: "targetStartDate",
      render: (targetStartDate) => {
        return targetStartDate
          ? moment(targetStartDate).format("DD-MM-YYYY")
          : "";
      },
    },
    {
      title: "Target End Date",
      dataIndex: "targetEndDate",
      key: "targetEndDate",
      render: (targetEndDate) => {
        return targetEndDate ? moment(targetEndDate).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate) => {
        return startDate ? moment(startDate).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate) => {
        return endDate ? moment(endDate).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "Total Service",
      dataIndex: "totalService",
      key: "totalService",
    },
    {
      title: "Service Done",
      dataIndex: "serviceDone",
      key: "serviceDone",
    },
    {
      title: "Service Pending",
      dataIndex: "servicePending",
      key: "servicePending",
    },
    {
      title: "Service Calendar",
      dataIndex: "serviceCalender",
      key: "serviceCalender",
    },
    {
      title: "Estimated Cost",
      dataIndex: "estimatedCost",
      key: "estimatedCost",
    },
    {
      title: "Actual Cost",
      dataIndex: "actualCost",
      key: "actualCost",
    },
    {
      title: "Attachment",
      dataIndex: "attachment",
      key: "attachment",
      render: (text, record) => {
        const filePath = record.attachment || "";
        const parts = filePath.split("/");
        const fileName = parts.length > 0 ? parts.pop() : "No Attachment";
        return fileName;
      },
    },
    // {
    //   title: "Transaction History",
    //   dataIndex: "transactionHistory",
    //   key: "transactionHistory",
    // },
    {
      title: "Expense Till Date",
      dataIndex: "expenseTillDate",
      key: "expenseTillDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => status.status,
    },
    {
      title: "Estimated Quote after Discount",
      dataIndex: "estimatedQuoteAfterDiscount",
      key: "estimatedQuoteAfterDiscount",
    },
    {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Link to={`/edit-order/${record.id}`}>
            <Button>Edit</Button>
          </Link>
        ),
      },
  ];



  return (
    <div>
      {" "}
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Assign Order</h2>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Assign Order</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {loading ? (
        <div className="flex items-center flex-col">
          <Spin />
        </div>
      ) : (
        <div className="p-5">
          <Card className="w-full">
            <div style={{ overflowX: "auto" }}>
              <Table dataSource={order} columns={columns} loading={loading} />
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default AssignOrder;
