import React, { useEffect, useState } from "react";
import { Breadcrumb, Card, Row, Col, Table, Spin } from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAssistantAccess } from "../../../actions/assistantAccess/assistantAccess";


const Access = () => {
  const dispatch = useDispatch();
  const access = useSelector((state) => state.access.access);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [control, setControl] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await dispatch(getAssistantAccess());
      } catch (error) {
        // Handle error if needed
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (access && access.user && access.user.panelControl !== undefined) {
      setUser(access.managerDetails);
      setControl(access.user.panelControl);
      setData([access.user]);
    } else {
      setUser([]);
      setControl(false); 
      setData([]); 
    }
  }, [access]);

  const trueFields = Object.entries(control).filter(
    ([key, value]) => value === true
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "emailId",
      key: "emailId",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
  ];

  const toTitleCase = (str) => {
    return str
      .replace(/_/g, " ")
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (match, index) => match.toUpperCase());
  };
  return (
    <div>
      {" "}
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Access</h2>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Access</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {loading ? (
        <div className="flex items-center flex-col">
          <Spin />
        </div>
      ) : (
        <Row gutter={[16, 16]} className="p-4">
          <Col span={24} md={16}>
            <Card className="w-full">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                General Information
              </h2>
              <div style={{overflowX:'auto'}}>
              <Table
                columns={columns}
                loading={loading}
                dataSource={data}
                pagination={false}
              />
              </div>
              <h2 className="text-xl font-semibold text-gray-800 m-5">
                Role Access
              </h2>
              <ul className="flex flex-wrap">
                {trueFields.map(([key, value]) => (
                  <li key={key} className="w-1/2 mb-2 flex items-center">
                    <div className="w-1 h-1 bg-black rounded-full mr-2"></div>
                    <p>
                      {toTitleCase(key)}
                    </p>
                  </li>
                ))}
              </ul>
            </Card>
          </Col>
          <Col span={24} md={8}>
            <Card className="w-full">
              <div>
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  Manager Details
                </h2>
                <div className="flex flex-col items-center">
                  <img
                    src="https://images.unsplash.com/photo-1631947430066-48c30d57b943?q=80&w=1432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Portrait"
                    className="rounded-md w-20 h-20 mb-4"
                  />
                  <h3 className="text-lg font-semibold">
                    {user.firstName} {user.lastName}
                  </h3>
                </div>
                <div className="ml-1 mt-2">
                  <p className="text-gray-900 text-sm font-semibold">
                    <MailOutlined /> &nbsp; Email :
                  </p>

                  <p className="text-gray-500 text-sm">{user.emailId}</p>
                </div>

                <div className="ml-1 mt-2">
                  <p className="text-gray-900 text-sm font-semibold ">
                    <PhoneOutlined />
                    &nbsp;Mobile Number :
                  </p>
                  <p className="text-gray-800 text-sm ">{user.mobileNumber}</p>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Access;
