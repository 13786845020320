import React, { useState,useEffect } from "react";
import { Layout, Row, Col, Space, Menu, Button, message } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  MenuOutlined,
MenuFoldOutlined,
MenuUnfoldOutlined
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faClipboardList,
  faShieldAlt,
  faTasks,
  faCog,
  faSignOutAlt,
  faDatabase,
  faInbox,
  faColumns,
  faClipboardCheck,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/img/furnishi_logo.png";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../../constants/actionTypes";
import OrderHistory from "../orderHistory/OrderHistory";
import { getSetting } from "../../actions/settings/settings";


const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const OrderHistoryLayout= () => {
  const { orderId}=useParams();
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const [factoryName, setFactoryName] = useState("");
  const [image, setImage] = useState(null);
  const [colors,setColors]=useState('');
  const [fontSize,setFontSize]=useState('');
  const [collapsed, setCollapsed] = useState(false);

  const localhost = `https://furnishi-server-test.onrender.com/`;
  const carouselStyle = {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
  };

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

 
  const handleLogout = () => {
    dispatch({ type: LOGOUT });
    message.success("Factory Manager logout successfully!");
    navigate("/");
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dispatch(getSetting());
        setFactoryName(result.setting.factoryName);
        setImage(result.setting.logo);
        setColors(result.setting.colors);
        setFontSize(result.setting.fontSize);
      } catch (error) {
        console.error("Error fetching enquiry data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const menuItems = [
    {
      key: "1",
      label: "Dashboard",
      icon: (
        <FontAwesomeIcon icon={faTachometerAlt} style={{ fontSize: "18px" }} />
      ),
      link: "/factory",
    },
    {
      key: "2",
      label: "Master",
      icon: <FontAwesomeIcon icon={faDatabase} style={{ fontSize: "18px" }} />,
      link: "/factory/master",
    },

    {
      key: "3",
      label: "Logout",
      icon: (
        <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: "18px" }} />
      ),
      onClick: handleLogout,
    },
  ];

  const menuSliderItems = [
    {
      key: "1",
      label: "Dashboard",
      icon: (
        <FontAwesomeIcon icon={faTachometerAlt} style={{ fontSize: "18px" }} />
      ),
      link: "/factory",
    },
    {
      key: "2",
      label: "Master",
      icon: <FontAwesomeIcon icon={faDatabase} style={{ fontSize: "18px" }} />,
      link: "/factory/master",
    },

    {
      key: "3",
      label: "Enquiry",
      icon: <FontAwesomeIcon icon={faInbox} style={{ fontSize: "18px" }} />,
      link: "/factory/enquiry",
    },
    {
      key: "4",
      label: "Orderlist",
      icon: (
        <FontAwesomeIcon icon={faClipboardList} style={{ fontSize: "18px" }} />
      ),
      link: "/factory/order-list",
    },
    {
      key: "5",
      label: "My Orders",
      icon: (
        <FontAwesomeIcon icon={faShoppingCart} style={{ fontSize: "18px" }} />
      ),
      link: "/factory/my-orders",
    },
    {
      key: "6",
      label: "Panel Manager",
      icon: <FontAwesomeIcon icon={faColumns} style={{ fontSize: "18px" }} />,
      link: "/factory/panel-manager",
    },
    {
      key: "7",
      label: "Assign Order",
      icon: <FontAwesomeIcon icon={faTasks} style={{ fontSize: "18px" }} />,
      link: "/factory/assign-order",
    },
    {
      key: "8",
      label: "Snaglist",
      icon: (
        <FontAwesomeIcon icon={faClipboardCheck} style={{ fontSize: "18px" }} />
      ),
      link: "/factory/snaglist",
    },
    {
      key: "9",
      label: "Default Role Access",
      icon: <FontAwesomeIcon icon={faShieldAlt} style={{ fontSize: "18px" }} />,
      link: "/factory/default-role-access",
    },
    {
      key: "10",
      label: "Settings",
      icon: <FontAwesomeIcon icon={faCog} style={{ fontSize: "18px" }} />,
      link: "/factory/settings",
    },

    {
      key: "11",
      label: "Logout",
      icon: (
        <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: "18px" }} />
      ),
      onClick: handleLogout,
    },
  ];

  const customMenuStyle = {
    background: "#fff",
    height: "100vh",
  };

  const customMenuItemStyle = {
    marginTop: "10px",
    fontSize: "16px",
  };

  useEffect(() => {
    // Set CSS variables based on the colors received
    const root = document.documentElement;
    root.style.setProperty("--primary-color", colors);
    root.style.setProperty("--font-size", `${fontSize}px`);
  }, [colors, fontSize]);

  const isDesktop = window.innerWidth > 992;

  return (
    <div className="layout-container">
      <Layout style={{ minHeight: "100vh" }}>
      <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            backgroundColor: `${colors}`,
          }}
        >
          <Row justify="space-between">
            <Col>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "64px",
                }}
              >
                {isDesktop ? null : (
                  <Button
                    type="text"
                    icon={
                      collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                    }
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                      fontSize: "16px",
                      width: 64,
                      height: 64,
                      color: "#fff",
                    }}
                  />
                )}
                {image ? (
                  <img
                    src={`${localhost}/${image}`}
                    alt="Logo"
                    className="logo-image"
                    style={{ height: "60px", borderRadius: "50%" }}
                  />
                ) : (
                  <img
                    src={logo}
                    alt="Logo"
                    className="logo-image"
                    style={{ height: "60px", borderRadius: "50%" }}
                  />
                )}

                <h1
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                    fontSize: "18px",
                  }}
                >
                  {factoryName === null
                    ? "Furnishi Services"
                    : `${factoryName}`}
                </h1>
              </div>
            </Col>
            <Col>
              <div className="desktop-menu">
                <Space>
                  <Menu
                    theme="dark"
                    mode="horizontal"
                    // defaultSelectedKeys={["1"]}
                  >
                    {menuItems.map((item) =>
                      item.subMenu ? (
                        <SubMenu
                          key={item.key}
                          icon={item.icon}
                          title={item.label}
                        >
                          {item.subMenu.map((subItem) => (
                            <Menu.Item
                              key={subItem.key}
                              icon={subItem.icon}
                              // style={{ paddingLeft: "30px" }}
                            >
                              <Link to={subItem.link}>{subItem.label}</Link>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      ) : (
                        <Menu.Item
                          key={item.key}
                          icon={item.icon}
                          onClick={item.key === "3" ? item.onClick : null}
                        >
                          <Link to={item.link}>{item.label}</Link>
                        </Menu.Item>
                      )
                    )}
                  </Menu>
                </Space>
              </div>
            </Col>
            <div className="mobile-menu-icon">
              <Button
                type="text"
                icon={<MenuOutlined />}
                onClick={toggleMobileMenu}
                style={{ color: "#fff" }}
              />
            </div>
          </Row>
        </Header>

        <Layout>
          <Sider
            // breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            width={260}
            style={{
              ...carouselStyle,
              overflowY: "auto",
              height: "100vh",
              position: isDesktop ? "fixed" : "static",
            }}
            collapsible
            collapsed={collapsed}
            trigger={null}
          >
            <Menu
              mode="inline"
              // defaultSelectedKeys={["1"]}
              style={customMenuStyle}
            >
              {menuSliderItems.map((item) => (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  style={customMenuItemStyle}
                  onClick={item.key === "11" ? item.onClick : null} //for side bar logout
                >
                  <Link to={item.link} style={{ textDecoration: "none" }}>
                    {item.label}
                  </Link>
                </Menu.Item>
              ))}

              <div className="demo-logo-vertical" />
            </Menu>
          </Sider>
          {/* )} */}
          {showMobileMenu && (
            <div className="mobile-menu show">
              <Menu theme="dark" mode="inline" inlineIndent={16}>
                {menuItems.map((item) =>
                  item.subMenu ? (
                    <SubMenu key={item.key} title={item.label} icon={item.icon}>
                      {item.subMenu.map((subItem) => (
                        <Menu.Item key={subItem.key} icon={subItem.icon}>
                          <Link to={subItem.link}>{subItem.label}</Link>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key={item.key}
                      icon={item.icon}
                      onClick={item.key === "3" ? item.onClick : null}
                    >
                      <Link to={item.link}>{item.label}</Link>
                    </Menu.Item>
                  )
                )}
              </Menu>
            </div>
          )}
          <Layout
            style={{
              marginLeft: isDesktop ? 260 : 0,
              minHeight: "100vh",
              overflow: "auto",
            }}>
            <Content>
         
               <OrderHistory orderId={orderId} />
            </Content>
            <Footer style={{ textAlign: "center", fontFamily: "Rajdhani" }}>
              Furnishi Services by @ Tech Astute
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

export default OrderHistoryLayout;
