import React, { useEffect, useState } from "react";
import { Breadcrumb, Tabs,Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import NewSnaglist from "./newSnaglist/NewSnaglist";
import SnaglistOpen from "./snaglistOpen/SnaglistOpen";
import SnaglistClosed from "./snaglistClosed/SnaglistClosed";
import { getLocation } from "../../actions/master/location";
import { getStatus } from "../../actions/master/status";
import { getProduct } from "../../actions/master/product";
import { getSnagCost } from "../../actions/master/snagCost";
import { getSnagIssue } from "../../actions/master/snagIssue";
import { getSnagSolution } from "../../actions/master/snagSolution";
import { getSnagAction } from "../../actions/master/snagAction";

const { TabPane } = Tabs;

const Snaglist = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const product = useSelector((state) => state.product.product);
  const status = useSelector((state) => state.status.status);
  const location = useSelector((state) => state.location.location);
  const snagCost = useSelector((state) => state.snagCost.snagCost);
  const snagIssue = useSelector((state) => state.snagIssue.snagIssue);
  const snagSolution = useSelector((state) => state.snagSolution.snagSolution);
  const snagAction = useSelector((state) => state.snagAction.snagAction);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          dispatch(getProduct()),
          dispatch(getStatus()),
          dispatch(getLocation()),
          dispatch(getSnagCost()),
          dispatch(getSnagSolution()),
          dispatch(getSnagIssue()),
          dispatch(getSnagAction()),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Snaglist</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            {" "}
            <a href="/factory">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Snaglist</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        <div>
          <Spin spinning={loading}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="New Snaglist" key="1">
                <NewSnaglist
                  product={product}
                  status={status}
                  location={location}
                  snagCost={snagCost}
                  snagIssue={snagIssue}
                  snagSolution={snagSolution}
                  snagAction={snagAction}
                />
              </TabPane>
              <TabPane tab="Snaglist Open" key="2">
                <SnaglistOpen />
              </TabPane>
              <TabPane tab="Snaglist Closed" key="3">
                <SnaglistClosed />
              </TabPane>
            </Tabs>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Snaglist;
