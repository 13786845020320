import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  orderlist: [],
  orderlistById:[],
  state: "idle",
  error: null,
  success: null,
};

export const orderlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_ORDERLIST:
      return {
        ...state,
        orderlist: action.payload.orderlist,
      };
    case actionTypes.GET_ORDERLIST:
      return {
        ...state,
        orderlist: action.payload,
      };
      case actionTypes.GET_ORDERLIST_BY_ID:
        return {
          ...state,
          orderlistById: action.payload,
        };

    default:
      return state;
  }
};

export default orderlistReducer;
