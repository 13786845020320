import React, { useEffect, useState } from "react";
import { Breadcrumb, Tabs, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import NewAssistantUser from "../panelManager/newAssistantUser/NewAssistantUser";
import AllAssistantUsers from "./allAssistantUsers/AllAssistantUsers";
import { getRoleAccess } from "../../actions/roleAccess/roleAccess";
import { getAssistantUser } from "../../actions/panelManager/assistantUser";

const { TabPane } = Tabs;

const PanelManager = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const role = useSelector((state) => state.roleAccess.roleAccess);
  const assistantUser = useSelector(
    (state) => state.assistantUser.assistantUser
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          dispatch(getRoleAccess()),
          dispatch(getAssistantUser()),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);
  return (
    <div>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Panel Manager</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            {" "}
            <a href="/factory">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Enquiry</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        <Spin spinning={loading}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="New Assistant User" key="1">
              <NewAssistantUser role={role} />
            </TabPane>
            <TabPane tab="All Assistant Users" key="2">
              <AllAssistantUsers assistantUser={assistantUser} />
            </TabPane>
          </Tabs>
        </Spin>
      </div>
    </div>
  );
};

export default PanelManager;
