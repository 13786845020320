import React, { useEffect, useState } from "react";
import { Table } from "antd";
import moment from "moment";
import axios from "axios";

const SnaglistClosed = () => {
  const [snaglist, setSnaglist] = useState([]);
  const [loading, setLoading] = useState(false);

  const api = axios.create({
    baseURL: "https://furnishi-server-test.onrender.com",
  });

  api.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      if (refreshToken) {
        config.headers["Refresh-Token"] = `Bearer ${refreshToken}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    const fetchOrderData = async (snaglistStatus, setDataFunction) => {
      try {
        setLoading(true);
        const response = await api.get(
          `fm/snaglist?snaglistStatus=${snaglistStatus}`
        );
        setDataFunction(response.data.data);
      } catch (error) {
        console.error(`Error fetching order close data:`, error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData("close", setSnaglist);
  }, []);

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
      render: (text, record) => {
        return record.location.pincode;
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (location) => location.name,
    },
    {
      title: "Customer Cordinator Name",
      dataIndex: "customerCordinatorName",
      key: "customerCordinatorName",
      render: (text, record) => {
        return record.customerCordinator.name;
      },
    },
    {
      title: "Customer Cordinator Number",
      dataIndex: "customerCordinatorNumber",
      key: "customerCordinatorNumber",
      render: (text, record) => {
        return record.customerCordinator.number;
      },
    },
    {
      title: "Source Cordinator",
      dataIndex: "sourceCordinator",
      key: "sourceCordinator",
      render: (text, record) => {
        return record.sourceCordinator.name;
      },
    },
    {
      title: "Source Cordinator Number",
      dataIndex: "sourceCordinatorNumber",
      key: "sourceCordinatorNumber",
      render: (text, record) => {
        return record.sourceCordinator.number;
      },
    },
    {
      title: "Factory Cordinator",
      dataIndex: "factoryCordinator",
      key: "factoryCordinator",
      render: (text, record) => {
        return record.factoryCordinator.name;
      },
    },
    {
      title: "Factory Cordinator Number",
      dataIndex: "factoryCordinatorNumber",
      key: "factoryCordinatorNumber",
      render: (text, record) => {
        return record.factoryCordinator.number;
      },
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (product) => product.product,
    },
    {
      title: "Product Id",
      dataIndex: "productId",
      key: "productId",
    },
    // {
    //   title: "Product Code",
    //   dataIndex: "productCode",
    //   key: "productCode",
    // },
    {
      title: "Sale Value",
      dataIndex: "saleValue",
      key: "saleValue",
    },
    {
      title: "Material Value",
      dataIndex: "materialValue",
      key: "materialValue",
    },
    {
      title: "Face Area",
      dataIndex: "faceArea",
      key: "faceArea",
    },
    {
      title: "Target Start Date",
      dataIndex: "targetStartDate",
      key: "targetStartDate",
      render: (targetStartDate) => {
        return targetStartDate
          ? moment(targetStartDate).format("DD-MM-YYYY")
          : "";
      },
    },
    {
      title: "Target End Date",
      dataIndex: "targetEndDate",
      key: "targetEndDate",
      render: (targetEndDate) => {
        return targetEndDate ? moment(targetEndDate).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate) => {
        return startDate ? moment(startDate).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate) => {
        return endDate ? moment(endDate).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "Issue",
      dataIndex: "snagIssue",
      key: "snagIssue",
      render: (snagIssue) => snagIssue.issue,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Solution",
      dataIndex: "snagSolution",
      key: "snagSolution",
      render: (snagSolution) => snagSolution.solution,
    },
    {
      title: "Action",
      dataIndex: "snagAction",
      key: "snagAction",
      render: (snagAction) => snagAction.action,
    },
    {
      title: "Cost",
      dataIndex: "snagCost",
      key: "snagCost",
      render: (snagCost) => snagCost.cost,
    },
    {
      title: "Attachment",
      dataIndex: "attachment",
      key: "attachment",
      render: (text, record) => {
        const filePath = record.attachment || "";
        const parts = filePath.split("/");
        const fileName = parts.length > 0 ? parts.pop() : "No Attachment";
        return fileName;
      },
    },
    {
      title: "Pic",
      dataIndex: "photo",
      key: "photo",
      render: (text, record) => {
        const filePath = record.photo || "";
        const parts = filePath.split("/");
        const fileName = parts.length > 0 ? parts.pop() : "No Pic";
        return fileName;
      },
    },
    {
      title: "Video",
      dataIndex: "video",
      key: "video",
      render: (text, record) => {
        const filePath = record.video || "";
        const parts = filePath.split("/");
        const fileName = parts.length > 0 ? parts.pop() : "No Video";
        return fileName;
      },
    },

    {
      title: "Total Service",
      dataIndex: "totalService",
      key: "totalService",
    },
    {
      title: "Service Done",
      dataIndex: "serviceDone",
      key: "serviceDone",
    },
    {
      title: "Service Pending",
      dataIndex: "servicePending",
      key: "servicePending",
    },
    {
      title: "Service Calendar",
      dataIndex: "serviceCalender",
      key: "serviceCalender",
    },
    {
      title: "Estimated Cost",
      dataIndex: "estimatedCost",
      key: "estimatedCost",
    },
    {
      title: "Actual Cost",
      dataIndex: "actualCost",
      key: "actualCost",
    },

    // {
    //   title: "Transaction History",
    //   dataIndex: "transactionHistory",
    //   key: "transactionHistory",
    // },
    {
      title: "Expense Till Date",
      dataIndex: "expenseTillDate",
      key: "expenseTillDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => status.status,
    },
    {
      title: "Estimated Quote after Discount",
      dataIndex: "estimatedQuoteAfterDiscount",
      key: "estimatedQuoteAfterDiscount",
    },
  ];

  return (
    <div style={{ overflowX: "auto" }}>
      <Table dataSource={snaglist} columns={columns} loading={loading} />
    </div>
  );
};

export default SnaglistClosed;
