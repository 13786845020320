import * as api from "../../api";
import {GET_ASSISTANT_ASSIGN_ORDER,UPDATE_ORDER} from "../../constants/actionTypes";

export const getAssistantAssignOrder= () => async (dispatch) => {
    try {
      const { data } = await api.getAssistantAssignOrder();
      dispatch({ type: GET_ASSISTANT_ASSIGN_ORDER, payload: data });
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  export const updateOrder = (formData) => async (dispatch) => {
    try {
        const { data } = await api.updateOrder(formData);
        dispatch({ type: UPDATE_ORDER, payload: data });
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};