import React, { useEffect,useState } from "react";
import { Breadcrumb, Tabs,Spin } from "antd";
import NewOrder from "./newOrder/NewOrder";
import OrderOpen from "./orderOpen/OrderOpen";
import OrderClosed from "./orderClosed/OrderClosed";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../../actions/master/product";
import { getStatus } from "../../actions/master/status";
import { getLocation } from "../../actions/master/location";

const { TabPane } = Tabs;

const MyOrders = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const product = useSelector((state) => state.product.product);
  const status = useSelector((state) => state.status.status);
  const location = useSelector((state) => state.location.location);
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
      await Promise.all([
        dispatch(getProduct()),
        dispatch(getStatus()),
        dispatch(getLocation()),
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

    fetchData();
  }, [dispatch]);

  return (
    <div>
     
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
    <h2 className="text-lg font-semibold text-gray-800">
      My Orders
    </h2>
    <Breadcrumb>
      <Breadcrumb.Item>
        {" "}
        <a href="/factory">Home</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>My Orders</Breadcrumb.Item>
    </Breadcrumb>
  </div>
  <div className="bg-white rounded-lg p-6 shadow-md m-5">
      <Spin spinning={loading}>
     
        <Tabs defaultActiveKey="1">
          <TabPane tab="New Order" key="1">
            <NewOrder
              status={status}
              product={product}
              location={location}
            />
          </TabPane>
          <TabPane tab="Order Open" key="2">
            <OrderOpen />
          </TabPane>
          <TabPane tab="Order Closed" key="3">
            <OrderClosed />
          </TabPane>
        </Tabs>
    
      </Spin>
      </div>
    </div>
  );
};

export default MyOrders;
