import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  setting: {
    factoryName: null,
    logo: null,
    fontSize: null,
    notification: null,
    color: null,
  },
  status: "idle",
  error: null,
  success: null,
};

export const assistantSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_AU_FONT_SIZE:
      return {
        ...state,
        setting: {
          ...state.setting,
          fontSize: action.payload,
        },
        status: "success",
        error: null,
      };
    case actionTypes.UPDATE_AU_NOTIFICATION:
      return {
        ...state,
        setting: {
          ...state.setting,
          notification: action.payload,
        },
        status: "success",
        error: null,
      };
    case actionTypes.UPDATE_AU_COLOR:
      return {
        ...state,
        setting: {
          ...state.setting,
          color: action.payload,
        },
        status: "success",
        error: null,
      };
    case actionTypes.GET_AU_SETTING:
      return {
        ...state,
        setting: {
          ...state.setting,
          ...action.payload,
        },
        status: "success",
      };
    default:
      return state;
  }
};

export default assistantSettingReducer;
