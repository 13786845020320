import React, { useState,useEffect } from "react";
import { Layout, Row, Col, Space, Menu, Button, message } from "antd";
import { Link, useLocation,useNavigate } from "react-router-dom";
import {
  MenuOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt ,faClipboardList,faShieldAlt,faTasks,faCog,faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import logo from "../../../assets/img/furnishi_logo.png";
import Dashboard from "../dashboard/dashboard";
import OrderList from "../orderlist/OrderList";
import Settings from "../settings/Settings";
import Access from "../access/Access";
import AssignOrder from "../assignOrder/AssignOrder";
import { getAuSetting } from "../../../actions/assistantSetting/assistantSetting";
import { useDispatch } from "react-redux";
import { LOGOUT_ASSISTANT } from "../../../constants/actionTypes";

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const localhost = `https://furnishi-server-test.onrender.com/`;

const AssistentLayout = () => {
  const location = useLocation();
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [factoryName, setFactoryName] = useState("");
  const [image, setImage] = useState(null);
  const [color,setColor]=useState('');
  const [collapsed, setCollapsed] = useState(false);
  const [fontSize, setFontSize] = useState("");

  const carouselStyle = {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
 
  useEffect(() => {
    const fetchData = async () => {
      try {
       
        const result = await dispatch(getAuSetting());
        setFactoryName(result.settings.factoryName);
        setImage(result.settings.logo);
        setColor(result.settings.colors);
        setFontSize(result.settings.fontSize);
      } catch (error) {
        console.error("Error fetching enquiry data:", error);
      } 
    };

    fetchData();
  }, [dispatch]);

  const handleLogout = () => {
    dispatch({ type: LOGOUT_ASSISTANT });
    // console.log("User");
    message.success("Assistant user logout successfully!");
    navigate("/assistant-login");
  };

  const isDesktop = window.innerWidth > 992;
  //   const menuItems = [
  //     {
  //       key: "1",
  //       label: "Dashboard",
  //       icon: <DashboardOutlined />,
  //       link: "/factory",
  //     },
  //     {
  //       key: "2",
  //       label: "Master",
  //       icon: <BookOutlined />,
  //       link: "/factory/master",
  //     },

  //     {
  //       key: "3",
  //       label: "Logout",
  //       icon: <LogoutOutlined />,
  //       // onClick: handleLogout,
  //     },
  //   ];

  const menuSliderItems = [
    {
      key: "1",
      label: "Dashboard",
      icon: <FontAwesomeIcon icon={faTachometerAlt} style={{fontSize:'18px'}} />,
      link: "/assistant/dashboard",
    },
    {
      key: "2",
      label: "Orderlist",
      icon:<FontAwesomeIcon icon={faClipboardList } style={{fontSize:'18px'}} />,
      link: "/assistant/orderlist",
    },
    {
      key: "3",
      label: "Access",
      icon:<FontAwesomeIcon icon={faShieldAlt} style={{fontSize:'18px'}} />,
      link: "/assistant/access",
    },
    {
      key: "4",
      label: "Assign Orders",
      icon:<FontAwesomeIcon icon={faTasks} style={{fontSize:'18px'}} />,
      link: "/assistant/assign-orders",
    },

    {
      key: "5",
      label: "Settings",
      icon: <FontAwesomeIcon icon={faCog} style={{fontSize:'18px'}}  />,
      link: "/assistant/settings",
    },
    {
      key: "6",
      label: "Logout",
      icon: <FontAwesomeIcon icon={faSignOutAlt} style={{fontSize:'18px'}}  />,
      onClick: handleLogout,
    },
  ];

  const customMenuStyle = {
    background: "#fff",
    height: "100vh",
  };

  const customMenuItemStyle = {
    marginTop: "10px",
    fontSize: "16px",
  };

  useEffect(() => {
    // Set CSS variables based on the colors received
    const root = document.documentElement;
    root.style.setProperty("--primary-color", color);
    root.style.setProperty("--font-size", `${fontSize}px`);
  }, [color,fontSize]);

  return (
    <div className="layout-container">
      <Layout style={{ minHeight: "100vh" }}>
        <Header style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            backgroundColor:`${color}`
          }}>
          <Row justify="space-between">
            <Col>
            <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "64px",
                }}
              >
            {isDesktop ? null : (
                <Button
                  type="text"
                  icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: "16px",
                    width: 64,
                    height: 64,
                    color:'#fff'
                  }}
                />
              )}
            
                 {image ? (
                  <img
                    src={`${localhost}/${image}`}
                    alt="Logo"
                    className="logo-image"
                    style={{ height: "60px", borderRadius: "50%" }}
                  />
                ) : (
                  <img
                    src={logo}
                    alt="Logo"
                    className="logo-image"
                    style={{ height: "60px", borderRadius: "50%" }}
                  />
                )}
                <h1
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                    fontSize: "18px",
                  }}
                >
                   {factoryName === null
                    ? "Furnishi Services"
                    : `${factoryName}`}
                </h1>
              </div>
            </Col>
            <Col>
              <div className="desktop-menu">
                <Space>
                  <Menu
                    theme="dark"
                    mode="horizontal"
                    // defaultSelectedKeys={["1"]}
                  >
                    {/* {menuItems.map((item) =>
                      item.subMenu ? (
                        <SubMenu
                          key={item.key}
                          icon={item.icon}
                          title={item.label}
                        >
                          {item.subMenu.map((subItem) => (
                            <Menu.Item
                              key={subItem.key}
                              icon={subItem.icon}
                              // style={{ paddingLeft: "30px" }}
                            >
                              <Link to={subItem.link}>{subItem.label}</Link>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      ) : (
                        <Menu.Item
                          key={item.key}
                          icon={item.icon}
                          onClick={item.key === "3" ? item.onClick : null}
                        >
                          <Link to={item.link}>{item.label}</Link>
                        </Menu.Item>
                      )
                    )} */}
                  </Menu>
                </Space>
              </div>
            </Col>
            <div className="mobile-menu-icon">
              <Button
                type="text"
                icon={<MenuOutlined />}
                onClick={toggleMobileMenu}
                style={{ color: "#fff" }}
              />
            </div>
          </Row>
        </Header>

        <Layout>
          <Sider
            // breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            width={260}
           
            style={{
              ...carouselStyle,
              overflowY: "auto",
              height: "100vh",
              position: isDesktop ? "fixed" : "static",
            }}
            collapsible
            collapsed={collapsed}
            trigger={null}
          >
            <Menu
              mode="inline"
              // defaultSelectedKeys={["1"]}
              style={customMenuStyle}
            >
              {menuSliderItems.map((item) => (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  style={customMenuItemStyle}
                  onClick={item.key === "6" ? item.onClick : null} //for side bar logout
                >
                  <Link to={item.link} style={{ textDecoration: "none" }}>
                    {item.label}
                  </Link>
                </Menu.Item>
              ))}

              <div className="demo-logo-vertical" />
            </Menu>
          </Sider>
          {/* )} */}
          {showMobileMenu && (
            <div className="mobile-menu show">
              <Menu theme="dark" mode="inline" inlineIndent={16}>
                {/* {menuItems.map((item) =>
                  item.subMenu ? (
                    <SubMenu key={item.key} title={item.label} icon={item.icon}>
                      {item.subMenu.map((subItem) => (
                        <Menu.Item key={subItem.key} icon={subItem.icon}>
                          <Link to={subItem.link}>{subItem.label}</Link>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key={item.key}
                      icon={item.icon}
                      onClick={item.key === "3" ? item.onClick : null}
                    >
                      <Link to={item.link}>{item.label}</Link>
                    </Menu.Item>
                  )
                )} */}
              </Menu>
            </div>
          )}
          <Layout   style={{
              marginLeft: isDesktop ? 260 : 0,
              minHeight: "100vh",
              overflow: "auto",
            }}>
            <Content>
              {location.pathname === "/assistant/dashboard" && <Dashboard />}
              {location.pathname === "/assistant/orderlist" && <OrderList />}
              {location.pathname === "/assistant/access" && <Access />}
              {location.pathname === "/assistant/assign-orders" && <AssignOrder />}
              {location.pathname === "/assistant/settings" && <Settings />}
            </Content>
            <Footer style={{ textAlign: "center", fontFamily: "Rajdhani" }}>
              Furnishi Services by @ Tech Astute
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

export default AssistentLayout;
