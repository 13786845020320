import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Space,
  Menu,
  Button,
  message,
  Card,
  Breadcrumb,
  Spin,
  Form,
  Input,
  DatePicker,
  Select,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  MenuOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faClipboardList,
  faShieldAlt,
  faTasks,
  faCog,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import logo from "../../../assets/img/furnishi_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getAuLocation } from "../../../actions/auLocation/auLocation";
import { getAuProduct } from "../../../actions/auProduct/auProduct";
import { getAuStatus } from "../../../actions/auStatus/auStatus";
import { updateOrder } from "../../../actions/assistantAssignOrder/assistantAssignOrder";
import { getAuSetting } from "../../../actions/assistantSetting/assistantSetting";
import { LOGOUT_ASSISTANT } from "../../../constants/actionTypes";

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;
const localhost = `https://furnishi-server-test.onrender.com/`;
const EditOrder = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [factoryName, setFactoryName] = useState("");
  const [logoImage, setLogoImage] = useState(null);
  const [color, setColor] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [fontSize, setFontSize] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [targetEndDate, setTargetEndDate] = useState(null);
  const [targetStartDate, setTargetStartDate] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [sourceCordinatorData, setSourceCordinatorData] = useState([]);
  const [factoryCordinatorData, setFactoryCordinatorData] = useState([]);
  const location = useSelector((state) => state.auLocation.location || []);
  const product = useSelector((state) => state.auProduct.product || []);
  const status = useSelector((state) => state.auStatus.status || []);
  // console.log(location);

  const carouselStyle = {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
  };

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dispatch(getAuSetting());
        setFactoryName(result.settings.factoryName);
        setLogoImage(result.settings.logo);
        setColor(result.settings.colors);
        setFontSize(result.settings.fontSize);
      } catch (error) {
        console.error("Error fetching enquiry data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(getAuLocation()),
          dispatch(getAuProduct()),
          dispatch(getAuStatus()),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    // Set CSS variables based on the colors received
    const root = document.documentElement;
    root.style.setProperty("--primary-color", color);
    root.style.setProperty("--font-size", `${fontSize}px`);
  }, [color, fontSize]);

  const api = axios.create({
    baseURL: "https://furnishi-server-test.onrender.com",
  });

  api.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      if (refreshToken) {
        config.headers["Refresh-Token"] = `Bearer ${refreshToken}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    const fetchCordinatorData = async (cordinatorTypeId, setDataFunction) => {
      try {
        const response = await api.get(
          `/au/cordinator?cordinatorTypeId=${cordinatorTypeId}`
        );
        setDataFunction(response.data.data);
      } catch (error) {
        console.error(
          `Error fetching ${
            cordinatorTypeId === 2 ? "Source" : "Factory"
          } Cordinator data:`,
          error
        );
      }
    };

    fetchCordinatorData(1, setCustomerData);
    fetchCordinatorData(2, setSourceCordinatorData);
    fetchCordinatorData(3, setFactoryCordinatorData);
  }, []);

  const fetchDataForOrderId = async (orderId, setDataFunction) => {
    try {
      // Use the configured axios instance to make the request
      const res = await api.get("/au/order", {
        params: {
          orderId: orderId,
        },
      });

      setDataFunction(res.data);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  useEffect(() => {
    fetchDataForOrderId(orderId, setOrderData);
  }, [orderId]);
  useEffect(() => {
    // When orderData changes, set the form values
    if (
      orderData &&
      orderData.order &&
      orderData.order.orders &&
      orderData.order.orders[0]
    ) {
      form.setFieldsValue({
        name: orderData.order.orders[0].name,
        mobileNumber: orderData.order.orders[0].mobileNumber,
        address: orderData.order.orders[0].address,
        locationId: orderData.order.orders[0].locationId,
        customerCordinatorId: orderData.order.orders[0].customerCordinatorId,
        sourceCordinatorId: orderData.order.orders[0].sourceCordinatorId,
        factoryCordinatorId: orderData.order.orders[0].factoryCordinatorId,
        productId: orderData.order.orders[0].productId,
        saleValue: orderData.order.orders[0].saleValue,
        materialValue: orderData.order.orders[0].materialValue,
        faceArea: orderData.order.orders[0].faceArea,
        totalService: orderData.order.orders[0].totalService,
        serviceDone: orderData.order.orders[0].serviceDone,
        serviceCalendar: orderData.order.orders[0].serviceCalender,
        servicePending: orderData.order.orders[0].servicePending,
        estimatedCost: orderData.order.orders[0].estimatedCost,
        actualCost: orderData.order.orders[0].actualCost,
        expenseTillDate: orderData.order.orders[0].expenseTillDate,
        estimatedQuoteAfterDiscount:
          orderData.order.orders[0].estimatedQuoteAfterDiscount,
        statusId: orderData.order.orders[0].statusId,
        targetStartDate: moment(
          orderData.order.orders[0].targetStartDate,
          "YYYY-MM-DD"
        ),
        targetEndDate: moment(
          orderData.order.orders[0].targetEndDate,
          "YYYY-MM-DD"
        ),
        startDate: moment(orderData.order.orders[0].startDate, "YYYY-MM-DD"),
        endDate: moment(orderData.order.orders[0].endDate, "YYYY-MM-DD"),
      });
    }
  }, [orderData, form]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const fileName = event.target.files[0];
      setImage(fileName);
    }
  };

  const handleLogout = () => {
    dispatch({ type: LOGOUT_ASSISTANT });
    // console.log("User");
    message.success("Assistant user logout successfully!");
    navigate("/assistant-login");
  };

  const isDesktop = window.innerWidth > 992;
  //   const menuItems = [
  //     {
  //       key: "1",
  //       label: "Dashboard",
  //       icon: <DashboardOutlined />,
  //       link: "/factory",
  //     },
  //     {
  //       key: "2",
  //       label: "Master",
  //       icon: <BookOutlined />,
  //       link: "/factory/master",
  //     },

  //     {
  //       key: "3",
  //       label: "Logout",
  //       icon: <LogoutOutlined />,
  //       // onClick: handleLogout,
  //     },
  //   ];

  const menuSliderItems = [
    {
      key: "1",
      label: "Dashboard",
      icon: (
        <FontAwesomeIcon icon={faTachometerAlt} style={{ fontSize: "18px" }} />
      ),
      link: "/assistant/dashboard",
    },
    {
      key: "2",
      label: "Orderlist",
      icon: (
        <FontAwesomeIcon icon={faClipboardList} style={{ fontSize: "18px" }} />
      ),
      link: "/assistant/orderlist",
    },
    {
      key: "3",
      label: "Access",
      icon: <FontAwesomeIcon icon={faShieldAlt} style={{ fontSize: "18px" }} />,
      link: "/assistant/access",
    },
    {
      key: "4",
      label: "Assign Orders",
      icon: <FontAwesomeIcon icon={faTasks} style={{ fontSize: "18px" }} />,
      link: "/assistant/assign-orders",
    },

    {
      key: "5",
      label: "Settings",
      icon: <FontAwesomeIcon icon={faCog} style={{ fontSize: "18px" }} />,
      link: "/assistant/settings",
    },
    {
      key: "6",
      label: "Logout",
      icon: (
        <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: "18px" }} />
      ),
      onClick: handleLogout,
    },
  ];
  const customMenuStyle = {
    background: "#fff",
    height: "100vh",
  };

  const customMenuItemStyle = {
    marginTop: "10px",
    fontSize: "16px",
  };

  const onFinish = async (values) => {
    try {
      // console.log("received values", values.attachment);
      setEditLoading(true);
      const formData = new FormData();
      formData.append("orderId", orderId);
      formData.append("name", values.name);
      formData.append("mobileNumber", values.mobileNumber);
      formData.append("address", values.address);
      formData.append("locationId", values.locationId);
      formData.append("customerCordinatorId", values.customerCordinatorId);
      formData.append("sourceCordinatorId", values.sourceCordinatorId);
      formData.append("factoryCordinatorId", values.factoryCordinatorId);
      formData.append("productId", values.productId);
      formData.append("saleValue", values.saleValue);
      formData.append("materialValue", values.materialValue);
      formData.append("faceArea", values.faceArea);
      formData.append("targetStartDate", targetStartDate);
      formData.append("targetEndDate", targetEndDate);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("totalService", values.totalService);
      formData.append("serviceDone", values.serviceDone);
      formData.append("servicePending", values.servicePending);
      formData.append("serviceCalender", values.serviceCalendar);
      formData.append("estimatedCost", values.estimatedCost);
      formData.append("actualCost", values.actualCost);
      formData.append("expenseTillDate", values.expenseTillDate);
      formData.append("statusId", values.statusId);
      formData.append(
        "estimatedQuoteAfterDiscount",
        values.estimatedQuoteAfterDiscount
      );
      formData.append("attachment", image);

      const response = await dispatch(updateOrder(formData));

      if (response.success) {
        message.success(response.message);
        form.resetFields();
        setTargetStartDate(null);
        setTargetEndDate(null);
        setStartDate(null);
        setEndDate(null);
        setImage(null);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(error.response?.data?.message || "An error occured");
    } finally {
      setEditLoading(false);
    }
  };

  return (
    <div className="layout-container">
      <Layout style={{ minHeight: "100vh" }}>
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            backgroundColor: `${color}`,
          }}
        >
          <Row justify="space-between">
            <Col>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "64px",
                }}
              >
                {isDesktop ? null : (
                  <Button
                    type="text"
                    icon={
                      collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                    }
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                      fontSize: "16px",
                      width: 64,
                      height: 64,
                      color: "#fff",
                    }}
                  />
                )}

                {image ? (
                  <img
                    src={`${localhost}/${logoImage}`}
                    alt="Logo"
                    className="logo-image"
                    style={{ height: "60px", borderRadius: "50%" }}
                  />
                ) : (
                  <img
                    src={logo}
                    alt="Logo"
                    className="logo-image"
                    style={{ height: "60px", borderRadius: "50%" }}
                  />
                )}
                <h1
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                    fontSize: "18px",
                  }}
                >
                  {factoryName === null
                    ? "Furnishi Services"
                    : `${factoryName}`}
                </h1>
              </div>
            </Col>
            <Col>
              <div className="desktop-menu">
                <Space>
                  <Menu
                    theme="dark"
                    mode="horizontal"
                    // defaultSelectedKeys={["1"]}
                  >
                    {/* {menuItems.map((item) =>
                      item.subMenu ? (
                        <SubMenu
                          key={item.key}
                          icon={item.icon}
                          title={item.label}
                        >
                          {item.subMenu.map((subItem) => (
                            <Menu.Item
                              key={subItem.key}
                              icon={subItem.icon}
                              // style={{ paddingLeft: "30px" }}
                            >
                              <Link to={subItem.link}>{subItem.label}</Link>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      ) : (
                        <Menu.Item
                          key={item.key}
                          icon={item.icon}
                          onClick={item.key === "3" ? item.onClick : null}
                        >
                          <Link to={item.link}>{item.label}</Link>
                        </Menu.Item>
                      )
                    )} */}
                  </Menu>
                </Space>
              </div>
            </Col>
            <div className="mobile-menu-icon">
              <Button
                type="text"
                icon={<MenuOutlined />}
                onClick={toggleMobileMenu}
                style={{ color: "#fff" }}
              />
            </div>
          </Row>
        </Header>

        <Layout>
          <Sider
            // breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            width={260}
            style={{
              ...carouselStyle,
              overflowY: "auto",
              height: "100vh",
              position: isDesktop ? "fixed" : "static",
            }}
            collapsible
            collapsed={collapsed}
            trigger={null}
          >
            <Menu
              mode="inline"
              // defaultSelectedKeys={["1"]}
              style={customMenuStyle}
            >
              {menuSliderItems.map((item) => (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  style={customMenuItemStyle}
                  onClick={item.key === "6" ? item.onClick : null} //for side bar logout
                >
                  <Link to={item.link} style={{ textDecoration: "none" }}>
                    {item.label}
                  </Link>
                </Menu.Item>
              ))}

              <div className="demo-logo-vertical" />
            </Menu>
          </Sider>
          {/* )} */}
          {showMobileMenu && (
            <div className="mobile-menu show">
              <Menu theme="dark" mode="inline" inlineIndent={16}>
                {/* {menuItems.map((item) =>
                  item.subMenu ? (
                    <SubMenu key={item.key} title={item.label} icon={item.icon}>
                      {item.subMenu.map((subItem) => (
                        <Menu.Item key={subItem.key} icon={subItem.icon}>
                          <Link to={subItem.link}>{subItem.label}</Link>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key={item.key}
                      icon={item.icon}
                      onClick={item.key === "3" ? item.onClick : null}
                    >
                      <Link to={item.link}>{item.label}</Link>
                    </Menu.Item>
                  )
                )} */}
              </Menu>
            </div>
          )}
          <Layout
            style={{
              marginLeft: isDesktop ? 260 : 0,
              minHeight: "100vh",
              overflow: "auto",
            }}
          >
            <Content>
              <div>
                <div className="flex justify-between items-center pt-5 pl-4 pr-4">
                  <h2 className="text-lg font-semibold text-gray-800">
                    Edit Order
                  </h2>
                  <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <a href="/assistant/assign-orders">Assign Order</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Edit Order</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                {loading ? (
                  <div className="flex items-center flex-col">
                    <Spin />
                  </div>
                ) : (
                  <div className="p-5">
                    <Card className="w-full">
                      <Form
                        form={form}
                        onFinish={onFinish}
                        name="myForm"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Row gutter={16}>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Name"
                              name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter name!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Name"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Mobile Number"
                              name="mobileNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter mobile number!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Mobile Number"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Address"
                              name="address"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter address!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Address"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>

                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Location"
                              name="locationId"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select location!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Location"
                                className="custom-input"
                              >
                                {location?.locations?.map((location) => (
                                  <Option key={location.id} value={location.id}>
                                    {location.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Customer Cordinator"
                              name="customerCordinatorId"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select customer cordinator!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Customer Cordinator"
                                className="custom-input"
                              >
                                {customerData?.map((customer) => (
                                  <Option
                                    key={customer.cordinatorTypeId}
                                    value={customer.cordinatorTypeId}
                                  >
                                    {customer.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Source Cordinator"
                              name="sourceCordinatorId"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select source cordinator!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Source Cordinator"
                                className="custom-input"
                              >
                                {sourceCordinatorData?.map((source) => (
                                  <Option
                                    key={source.cordinatorTypeId}
                                    value={source.cordinatorTypeId}
                                  >
                                    {source.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Factory Cordinator"
                              name="factoryCordinatorId"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select factory cordinator!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Factory Cordinator"
                                className="custom-input"
                              >
                                {factoryCordinatorData?.map((factory) => (
                                  <Option
                                    key={factory.cordinatorTypeId}
                                    value={factory.cordinatorTypeId}
                                  >
                                    {factory.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Product"
                              name="productId"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select product!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Product"
                                className="custom-input"
                              >
                                {product?.products?.map((product) => (
                                  <Option key={product.id} value={product.id}>
                                    {product.product}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Sale Value"
                              name="saleValue"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter sale value!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Sale Value"
                                type="number"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Material Value"
                              name="materialValue"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter material value!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Material Value"
                                type="number"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Face Area"
                              name="faceArea"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter face area!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Face Area"
                                type="number"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Target Start Date"
                              name="targetStartDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select target start date!",
                                },
                              ]}
                            >
                              <DatePicker
                                className="custom-input"
                                style={{ width: "100%" }}
                                onChange={(date, dateString) => {
                                  setTargetStartDate(dateString);
                                }}
                                format="MM/DD/YYYY"
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Target End Date"
                              name="targetEndDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select target end date!",
                                },
                              ]}
                            >
                              <DatePicker
                                className="custom-input"
                                style={{ width: "100%" }}
                                onChange={(date, dateString) => {
                                  setTargetEndDate(dateString);
                                }}
                                format="MM/DD/YYYY"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Start Date"
                              name="startDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select start date!",
                                },
                              ]}
                            >
                              <DatePicker
                                className="custom-input"
                                style={{ width: "100%" }}
                                onChange={(date, dateString) => {
                                  setStartDate(dateString);
                                }}
                                format="MM/DD/YYYY"
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="End Date"
                              name="endDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select end date!",
                                },
                              ]}
                            >
                              <DatePicker
                                className="custom-input"
                                style={{ width: "100%" }}
                                onChange={(date, dateString) => {
                                  setEndDate(dateString);
                                }}
                                format="MM/DD/YYYY"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Total Service"
                              name="totalService"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter total services!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Total Services"
                                type="number"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item label="Service Done" name="serviceDone">
                              <Input
                                placeholder="Service Done"
                                type="number"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Service Pending"
                              name="servicePending"
                            >
                              <Input
                                placeholder="Service Pending"
                                type="number"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Service Calendar"
                              name="serviceCalendar"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter service calendar!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Service Calendar"
                                type="number"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Estimated Cost"
                              name="estimatedCost"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter estimated cost!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Estimated Cost"
                                type="number"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Actual Cost"
                              name="actualCost"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter actual cost!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Actual Cost"
                                type="number"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Attachment"
                              name="attachment"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter attachment!",
                                },
                              ]}
                            >
                              <Input
                                type="file"
                                onChange={onImageChange}
                                className="filetype custom-input"
                              />
                            </Form.Item>
                          </Col>

                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Expense Till Date"
                              name="expenseTillDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter expense till date!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Expense Till Date"
                                type="number"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            <Form.Item
                              label="Status"
                              name="statusId"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select status!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Status"
                                className="custom-input"
                              >
                                {status?.status?.map((status) => (
                                  <Option key={status.status} value={status.id}>
                                    {status.status}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col lg={12} sm={24} xs={24} md={12}>
                            {" "}
                            <Form.Item
                              label="Estimated Quote after Discount"
                              name="estimatedQuoteAfterDiscount"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please enter estimated quote after discount!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Estimated Quote after Discount"
                                type="number"
                                className="custom-input"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Form.Item>
                          <Button
                            className="default-btn"
                            htmlType="submit"
                            loading={editLoading}
                          >
                            Update
                          </Button>
                        </Form.Item>
                      </Form>
                    </Card>
                  </div>
                )}
              </div>
            </Content>
            <Footer style={{ textAlign: "center", fontFamily: "Rajdhani" }}>
              Furnishi Services by @ Tech Astute
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

export default EditOrder;
