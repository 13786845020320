import * as api from "../../api";
import {ADD_ORDERLIST,GET_ORDERLIST, GET_ORDERLIST_BY_ID} from "../../constants/actionTypes";


export const addOrderList = (orderlistInfo) => async (dispatch) => {
    try {
        const { data } = await api.addOrderList(orderlistInfo);
        dispatch({ type: ADD_ORDERLIST, payload: data });
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const getOrderListById= (id) => async (dispatch) => {
    try {
      const { data } = await api.getOrderList(id);
      dispatch({ type: GET_ORDERLIST_BY_ID, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  export const getOrderList= () => async (dispatch) => {
    try {
      const { data } = await api.getOrderList();
      dispatch({ type: GET_ORDERLIST, payload: data });
    } catch (error) {
      console.log(error);
    }
  };