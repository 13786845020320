import React, { useState } from "react";
import { Form, Row, Col, Button, message } from "antd";
import { useDispatch } from "react-redux";
import { SketchPicker } from 'react-color';
import { updateAuColor } from "../../../actions/assistantSetting/assistantSetting";

const Color = () => {
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(false);
  const [colors, setColors] = useState("#ffffff");
  const [form] = Form.useForm();

  const onFinish = async () => {
    try {
      setLoading(true);
      const res = await dispatch(updateAuColor({ colors }));
      if (res.success) {
        message.success(res.message);
        form.resetFields();
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      message.error(error.response.data.message);
    }finally{
      setLoading(false);
    }
  };

  const handleColorChange = (newColor) => {
    setColors(newColor.hex);
  };
  console.log(colors)

  return (
    <Form
      form={form}
      onFinish={onFinish}
      name="myForm"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Color"
            name="color"
            rules={[{ required: true, message: "Please enter color!" }]}
          >
            <SketchPicker color={colors} onChange={handleColorChange} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button className="default-btn" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Color;
