import * as api from "../../api";
import {GET_ORDER_HISTORY,GET_SNAGLIST_HISTORY} from "../../constants/actionTypes";

export const getOrderHistory= (orderId) => async (dispatch) => {
    try {
      const { data } = await api.getOrderHistory(orderId);
      dispatch({ type: GET_ORDER_HISTORY, payload: data });
    } catch (error) {
      console.log(error);
    }
  };


  export const getSnaglistHistory= (snaglistId) => async (dispatch) => {
    try {
      const { data } = await api.getSnaglistHistory(snaglistId);
      dispatch({ type: GET_SNAGLIST_HISTORY, payload: data });
    } catch (error) {
      console.log(error);
    }
  };