import * as api from "../../api";
import {ADD_SNAGLIST,GET_SNAGLIST} from "../../constants/actionTypes";


export const addSnaglist = (formData) => async (dispatch) => {
    try {
        const { data } = await api.addSnaglist(formData);
        dispatch({ type: ADD_SNAGLIST, payload: data });
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const getSnaglist = (snaglistStatus) => async (dispatch) => {
    try {
      const { data } = await api.getSnaglist(snaglistStatus);
      dispatch({ type: GET_SNAGLIST, payload: data });
      return data;
    } catch (error) {
      console.log(error);
    }
  };