import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  product: [],
  state: "idle",
  error: null,
  success: null,
};

export const auProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_AU_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };

    default:
      return state;
  }
};

export default auProductReducer;
