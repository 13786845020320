import * as api from "../../api";
import { GET_AU_PRODUCT } from "../../constants/actionTypes";

export const getAuProduct = () => async (dispatch) => {
  try {
    const { data } = await api.getAuProduct();
    dispatch({ type: GET_AU_PRODUCT, payload: data });
    return data;
  } catch (error) {
    console.log(error);
  }
};
