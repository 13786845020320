import React, { useEffect,useState } from "react";
import { Breadcrumb, Tabs,Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import NewEnquiry from "./newEnquiry/NewEnquiry";
import AllEnquiry from "./allEnquiry/AllEnquiry";
import { getProduct } from "../../actions/master/product";
import { getStatusAction } from "../../actions/master/statusAction";
import { getStatus } from "../../actions/master/status";
import { getCarcass } from "../../actions/master/carcass";
import { getShutter } from "../../actions/master/shutter";

const { TabPane } = Tabs;

const Enquiry = () => {
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(false);
  const product = useSelector((state) => state.product.product);
  const statusAction = useSelector((state) => state.statusAction.statusAction);
  const status = useSelector((state) => state.status.status);
  const carcass = useSelector((state) => state.carcass.carcass);
  const shutter = useSelector((state) => state.shutter.shutter);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
      await Promise.all([
        dispatch(getProduct()),
        dispatch(getStatusAction()),
        dispatch(getStatus()),
        dispatch(getCarcass()),
        dispatch(getShutter()),
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

    fetchData();
  }, [dispatch]);
  return (
    <div>
    <div className="flex justify-between items-center pt-5 pl-4 pr-4">
    <h2 className="text-lg font-semibold text-gray-800">
      Enquiry
    </h2>
    <Breadcrumb>
      <Breadcrumb.Item>
        {" "}
        <a href="/factory">Home</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Enquiry</Breadcrumb.Item>
    </Breadcrumb>
  </div>
  <div className="bg-white rounded-lg p-6 shadow-md m-5">
      <Spin spinning={loading}>
    
        <Tabs defaultActiveKey="1">
          <TabPane tab="New Enquiry" key="1">
            <NewEnquiry
              status={status}
              carcass={carcass}
              shutter={shutter}
              statusAction={statusAction}
              product={product}
            />
          </TabPane>
          <TabPane tab="All Enquiries" key="2">
            <AllEnquiry />
          </TabPane>
        </Tabs>
      
      </Spin>
    </div>
    </div>
  );
};

export default Enquiry;
