import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  setting: {
    factoryName: null,
    logo: null,
    fontSize: null,
    notification: null,
    color: null,
  },
  status: "idle",
  error: null,
  success: null,
};

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_FACTORY_NAME:
      return {
        ...state,
        setting: {
          ...state.setting,
          factoryName: action.payload,
        },
        status: "success",
        error: null,
      };
    case actionTypes.UPDATE_LOGO:
      return {
        ...state,
        setting: {
          ...state.setting,
          logo: action.payload,
        },
        status: "success",
        error: null,
      };
    case actionTypes.UPDATE_FONT_SIZE:
      return {
        ...state,
        setting: {
          ...state.setting,
          fontSize: action.payload,
        },
        status: "success",
        error: null,
      };
    case actionTypes.UPDATE_NOTIFICATION:
      return {
        ...state,
        setting: {
          ...state.setting,
          notification: action.payload,
        },
        status: "success",
        error: null,
      };
    case actionTypes.UPDATE_COLOR:
      return {
        ...state,
        setting: {
          ...state.setting,
          color: action.payload,
        },
        status: "success",
        error: null,
      };
    case actionTypes.GET_SETTING:
      return {
        ...state,
        setting: {
          ...state.setting,
          ...action.payload,
        },
        status: "success",
      };
    default:
      return state;
  }
};

export default settingsReducer;
