import React, { useState, useEffect } from "react";
import { Table } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getOrderList } from "../../../actions/orderlist/orderlist";

const OrderLists = () => {
  const dispatch = useDispatch();
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const order = useSelector((state) => state.orderlist.orderlist);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([dispatch(getOrderList())]);
        setOrderList(order.data);
      } catch (error) {
        console.error("Error fetching enquiry data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, order]);

  console.log(order);

  const columns = [
    {
      title: "Received Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      render: (receivedDate) => {
        return receivedDate ? moment(receivedDate).format("DD-MM-YYYY") : "";
      },
      fixed: "left",
    },
    {
      title: "Target Date",
      dataIndex: "targetDate",
      key: "targetDate",
      render: (targetDate) => {
        return targetDate ? moment(targetDate).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Customer Number",
      dataIndex: "customerNumber",
      key: "customerNumber",
    },
    {
      title: "Site Address",
      dataIndex: "siteAddress",
      key: "siteAddress",
    },
    {
      title: "Site Pincode",
      dataIndex: "sitePincode",
      key: "sitePincode",
    },
    {
      title: "Site Google Location",
      dataIndex: "siteGoogleLocation",
      key: "siteGoogleLocation",
    },
    {
      title: "Source",
      dataIndex: "sourceId",
      key: "sourceId",
    },
    {
      title: "Customer Cordinator Name",
      dataIndex: "customerCordinatorName",
      key: "customerCordinatorName",
      render: (text, record) => {
        return record.customerCordinator.name;
      },
    },
    {
      title: "Customer Cordinator Number",
      dataIndex: "customerCordinatorNumber",
      key: "customerCordinatorNumber",
      render: (text, record) => {
        return record.customerCordinator.number;
      },
    },
    {
      title: "Source Cordinator",
      dataIndex: "sourceCordinator",
      key: "sourceCordinator",
      render: (text, record) => {
        return record.sourceCordinator.name;
      },
    },
    {
      title: "Source Cordinator Number",
      dataIndex: "sourceCordinatorNumber",
      key: "sourceCordinatorNumber",
      render: (text, record) => {
        return record.sourceCordinator.number;
      },
    },
    {
      title: "Factory Cordinator",
      dataIndex: "factoryCordinator",
      key: "factoryCordinator",
      render: (text, record) => {
        return record.factoryCordinator.name;
      },
    },
    {
      title: "Factory Cordinator Number",
      dataIndex: "factoryCordinatorNumber",
      key: "factoryCordinatorNumber",
      render: (text, record) => {
        return record.factoryCordinator.number;
      },
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (product) => product.product,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (location) => location.name,
    },
    {
      title: "No Of Services",
      dataIndex: "noOfServices",
      key: "noOfServices",
    },
    {
      title: "Area (Sqft)",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Order Value",
      dataIndex: "orderValue",
      key: "orderValue",
    },
    {
      title: "Payment Received",
      dataIndex: "paymentReceived",
      key: "paymentReceived",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => status.status,
    },
    {
      title: "Carcass",
      dataIndex: "carcass",
      key: "carcass",
      render: (carcass) => carcass?.carcass,
    },
    {
      title: "Shutter",
      dataIndex: "shutter",
      key: "shutter",
      render: (shutter) => shutter?.shutter,
    },
    {
      title: "Designer",
      dataIndex: "designer",
      key: "designer",
      render: (designer) => designer?.designer,
    },
    {
      title: "Final Site Surveyor",
      dataIndex: "siteSurveyor",
      key: "siteSurveyor",
      render: (siteSurveyor) => siteSurveyor.siteSurveyor,
    },
    {
      title: "Sales Person",
      dataIndex: "salesPerson",
      key: "salesPerson",
      render: (salesPerson) => salesPerson.salesPerson,
    },
    {
      title: "Factory Engineer",
      dataIndex: "factoryEngineer",
      key: "factoryEngineer",
      render: (factoryEngineer) => factoryEngineer.factoryEngineer,
    },
    {
      title: "Work Start Time",
      dataIndex: "workStartTime",
      key: "workStartTime",
    },
    {
      title: "Work End Time",
      dataIndex: "workEndTime",
      key: "workEndTime",
    },
    {
      title: "Indent Number",
      dataIndex: "indentNumber",
      key: "indentNumber",
    },
    {
      title: "Indent Release",
      dataIndex: "indentRelease",
      key: "indentRelease",
      render: (indentRelease) => {
        return indentRelease ? moment(indentRelease).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "Design Clearance Date",
      dataIndex: "designClearance",
      key: "designClearance",
      render: (designClearance) => {
        return designClearance
          ? moment(designClearance).format("DD-MM-YYYY")
          : "";
      },
    },
    {
      title: "Account Clearance",
      dataIndex: "accountClearance",
      key: "accountClearance",
      render: (accountClearance) => {
        return accountClearance
          ? moment(accountClearance).format("DD-MM-YYYY")
          : "";
      },
    },
    {
      title: "Shop Documents",
      dataIndex: "shopDocuments",
      key: "shopDocuments",
      render: (shopDocuments) => {
        return shopDocuments ? moment(shopDocuments).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "Stock Check",
      dataIndex: "stockCheck",
      key: "stockCheck",
      render: (stockCheck) => {
        return stockCheck ? moment(stockCheck).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "PO Prepare",
      dataIndex: "poPrepare",
      key: "poPrepare",
      render: (poPrepare) => {
        return poPrepare ? moment(poPrepare).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "PO Approval",
      dataIndex: "poApproval",
      key: "poApproval",
      render: (poApproval) => {
        return poApproval ? moment(poApproval).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "PO Release",
      dataIndex: "poRelease",
      key: "poRelease",
      render: (poRelease) => {
        return poRelease ? moment(poRelease).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "Job Work Done",
      dataIndex: "jobWorkDone",
      key: "jobWorkDone",
      render: (jobWorkDone) => {
        return jobWorkDone ? moment(jobWorkDone).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "Raw Material Available",
      dataIndex: "rawMaterialAvailable",
      key: "rawMaterialAvailable",
      render: (rawMaterialAvailable) => {
        return rawMaterialAvailable
          ? moment(rawMaterialAvailable).format("DD-MM-YYYY")
          : "";
      },
    },
    {
      title: "Other Material Available",
      dataIndex: "otherMaterialAvailable",
      key: "otherMaterialAvailable",
      render: (otherMaterialAvailable) => {
        return otherMaterialAvailable
          ? moment(otherMaterialAvailable).format("DD-MM-YYYY")
          : "";
      },
    },
    {
      title: "Paint Material Production",
      dataIndex: "paintMaterialProduction",
      key: "paintMaterialProduction",
      render: (paintMaterialProduction) => {
        return paintMaterialProduction
          ? moment(paintMaterialProduction).format("DD-MM-YYYY")
          : "";
      },
    },
    {
      title: "Other Material Production",
      dataIndex: "otherMaterialProduction",
      key: "otherMaterialProduction",
      render: (otherMaterialProduction) => {
        return otherMaterialProduction
          ? moment(otherMaterialProduction).format("DD-MM-YYYY")
          : "";
      },
    },
    {
      title: "Panel Production",
      dataIndex: "panelProduction",
      key: "panelProduction",
      render: (panelProduction) => {
        return panelProduction
          ? moment(panelProduction).format("DD-MM-YYYY")
          : "";
      },
    },
    {
      title: "Assembly",
      dataIndex: "assembly",
      key: "assembly",
      render: (assembly) => {
        return assembly ? moment(assembly).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "Cleaning",
      dataIndex: "cleaning",
      key: "cleaning",
      render: (cleaning) => {
        return cleaning ? moment(cleaning).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "Packing",
      dataIndex: "packing",
      key: "packing",
      render: (packing) => {
        return packing ? moment(packing).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "Dispatch",
      dataIndex: "dispatch",
      key: "dispatch",
      render: (dispatch) => {
        return dispatch ? moment(dispatch).format("DD-MM-YYYY") : "";
      },
    },
  ];

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <Table dataSource={orderList} columns={columns} loading={loading} />
      </div>
    </>
  );
};

export default OrderLists;
