import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  assignOrder: [],
  state: "idle",
  error: null,
  success: null,
};

export const assignOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ASSIGN_ORDER:
      return {
        ...state,
        assignOrder: action.payload.assignOrder,
      };
    default:
      return state;
  }
};

export default assignOrderReducer;
