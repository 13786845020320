import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  status: [],
  state: "idle",
  error: null,
  success: null,
};

export const auStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_AU_STATUS:
      return {
        ...state,
        status: action.payload,
      };

    default:
      return state;
  }
};

export default auStatusReducer;
