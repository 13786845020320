import React, { useState } from "react";
import { Form, Row, Col, Switch, Button, message } from "antd";
import { useDispatch } from "react-redux";
import { updateAuNotification } from "../../../actions/assistantSetting/assistantSetting";

const Notification = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      console.log("Received Value", values);
      setLoading(true);
      const res = await dispatch(updateAuNotification(values));
      if (res.success) {
        message.success(res.message);
        form.resetFields();
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      name="myForm"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Notification"
            name="notification"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button className="default-btn" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Notification;
