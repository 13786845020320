import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  assignOrder: [],
  order:[],  //for update order
  state: "idle",
  error: null,
  success: null,
};

export const assignOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ASSISTANT_ASSIGN_ORDER:
      return {
        ...state,
        assignOrder: action.payload,
      };
      case actionTypes.UPDATE_ORDER:
        return {
          ...state,
          success: action.payload,
          error: null,
        };
    default:
      return state;
  }
};

export default assignOrderReducer;
