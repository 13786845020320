import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  orderHistory: [],
  snaglistHistory:[],
  state: "idle",
  error: null,
  success:null
};

export const historyReducer = (state = initialState, action) => {
  switch (action.type) {
  
      case actionTypes.GET_ORDER_HISTORY:
        return {
          ...state,
          orderHistory: action.payload,
        };
        case actionTypes.GET_SNAGLIST_HISTORY:
          return {
            ...state,
            snaglistHistory: action.payload,
          };
    
  
    default:
      return state;
  }
};

export default historyReducer;
