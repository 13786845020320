
import React, { useState } from "react";
import { Form, Input, Button, message, Carousel} from "antd";
import image1 from "../../assets/img/image1.jpg";
import image2 from "../../assets/img/image2.webp";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  login,
  VerifyOtp
} from "../../actions/fmLogin/auth";
import OtpInput from "../otpInput/OtpInput";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loginStep, setLoginStep] = useState("login");
  const [emailId, setEmailId] = useState("");
  const [loading, setLoading] = useState(false);

 
  const onFinish = async (values) => {
    try {
      const data = {
        emailId: values.emailId,
        role: "factoryManager",
      };
      console.log("Received values:", data);
      setLoading(true);
      const res = await dispatch(login(data));
      setLoginStep("getOtp");
      message.success(res.message);
      setEmailId(values.emailId);
      form.resetFields();
    } catch (error) {
      console.error("Error updating login:", error);
      message.error(error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  const onOtpSubmit = async (otp) => {
    try {
      if (!emailId) {
        return;
      }
      setLoading(true);
      const data = {
        emailId,
        otp,
        role: "factoryManager",
      };
      const res = await dispatch(VerifyOtp(data));
      message.success(res.message);
   
      navigate("/factory");
    } catch (error) {
      console.error("An error occurred during verify-otp:", error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };



  return (
    <>
      <div className="min-h-screen flex items-center justify-center card-bg">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row w-10/12 lg:w-9/12 bg-white rounded-xl mx-auto shadow-lg overflow-hidden">
            <div
              className="w-full lg:w-1/2 flex flex-col items-center justify-center bg-no-repeat bg-cover bg-center hidden md:block"
              style={{ height: "100%" }}
            >
              <Carousel
                autoplay
                autoplaySpeed={2000}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ height: "100%" }}>
                  <img
                    src={image1}
                    alt="Carousel 1"
                    style={{
                      width: "100%",
                      height: "80vh",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div style={{ height: "100%" }}>
                  <img
                    src={image2}
                    alt="Carousel 2"
                    style={{
                      width: "100%",
                      height: "80vh",
                      objectFit: "cover",
                    }}
                  />
                </div>
               
              </Carousel>
            </div>
            <div className="w-full lg:w-1/2 py-12 px-12 mt-8">
              <h2 className="text-3xl mb-4 ">
                {loginStep === "login" ? "Login" : "Get OTP"}
              </h2>
              <p className="mb-4">
                {loginStep === "login"
                  ? "Sign in to your account."
                  : "Enter the OTP sent to your email address"}
              </p>

              {loginStep === "getOtp" && (
                 <OtpInput length={6} onOtpSubmit={onOtpSubmit} />
              )}

              {loginStep === "login" && (
                <>
                  <Form
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    form={form}
                  >
                    <Form.Item
                      name="emailId"
                      rules={[
                        { required: true, message: "Please input your email!" },
                        {
                          type: "email",
                          message: "Please enter a valid email address!",
                        },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>

                 

                    <Form.Item>
                      <Button
                        htmlType="submit"
                        className="default-btn w-full"
                        loading={loading}
                      >
                        Login
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}

              {/* Already registered link */}
              {loginStep === "login" ? (
                <div className="text-center font-poppins">
                  Do not have account? <a href="/">Register here</a>
                </div>
              ) : (
                <div className="text-center font-poppins">
                  <a href="/login">Resend OTP</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;


