import React from "react";
import { Breadcrumb, Tabs} from "antd";
import CreateNewRole from "./createNewRole/CreateNewRole";

const { TabPane } = Tabs;

const DefaultRoleAccess = () => {
  return (
    <div>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">
          Default Role Access
        </h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            {" "}
            <a href="/factory">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Default Role Access</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Create New Role" key="1">
            <CreateNewRole />
          </TabPane>
          <TabPane tab="Manage Role" key="2"></TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default DefaultRoleAccess;
