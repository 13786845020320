import React, { useEffect, useState } from "react";
import { Breadcrumb, Table, Button, Modal, Select, message } from "antd";
import { useDispatch} from "react-redux";
import { getOrder } from "../../actions/order/order";
import { getAssistantUser } from "../../actions/panelManager/assistantUser";
import { assignOrder } from "../../actions/assignOrder/assignOrder";

const AssignOrder = () => {
  const dispatch = useDispatch();
  // const assistantUser = useSelector(
  //   (state) => state.assistantUser.assistantUser
  // );
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [user, setUser] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // const order = useSelector((state) => state.order.order);
  const { Option } = Select;

  //   console.log(order);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await dispatch(getOrder("open"));
        setOrders(result.data);
      } catch (error) {
        console.error("Error fetching enquiry data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleAssignClick = (orderId) => {
    setSelectedOrderId(orderId);
    setModalVisible(true);
  };

  const handleDropdownChange = (value) => {
    setSelectedUser(value);
  };

  const handleSearchSubmit = async () => {
    try {
      setAddLoading(true);
      if (selectedOrderId && selectedUser) {
        const data = {
          orderId: selectedOrderId,
          assistantUserId: selectedUser,
        };
        console.log(data);
        const res = await dispatch(assignOrder(data));

        if (res.success) {
          message.success(res.message);
          setSelectedOrderId(null);
          setSelectedUser(null);
          setModalVisible(false);
        }
      } else {
        console.error("Please select both Order ID and Assistant User");
      }
    } catch (error) {
      console.error("Error posting assignment:", error);
      message.error(error.response.data.message);
    } finally {
      setAddLoading(false);
    }
  };

  const handleModalCancel = () => {
    setSelectedOrderId(null);
    setSelectedUser(null);
    setModalVisible(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await dispatch(getAssistantUser());
        setUser(result.data);
      } catch (error) {
        console.error("Error fetching enquiry data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },
    {
      title: "Order Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Order Assign To",
      dataIndex: "assistantUsers",
      key: "assignTo",
      render: (assistantUsers) => (
        <>
          {assistantUsers.map((user) => (
            <div key={user.id}>{user.name ? user.name : "-"}</div>
          ))}
        </>
      ),
    },
    {
      title: "Order Assign Number",
      dataIndex: "assistantUsers",
      key: "assignNumber",
      render: (assistantUsers) => (
        <>
          {assistantUsers.map((user) => (
            <div key={user.id}>
              {user.mobileNumber ? user.mobileNumber : "-"}
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (text, record) => (
        <Button
          className="default-btn"
          onClick={() => handleAssignClick(record.id)}
        >
          Assign
        </Button>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Assign Order</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            {" "}
            <a href="/factory">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Assign Order</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        <div style={{ overflowX: "auto" }}>
          <Table dataSource={orders} columns={columns} loading={loading} />
        </div>

        <Modal
          title="Assign Order"
          open={modalVisible}
          onOk={handleSearchSubmit}
          confirmLoading={addLoading}
          okButtonProps={{ className: "default-btn" }}
          onCancel={handleModalCancel}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          bodyStyle={{ width: "400px" }}
        >
          <Select
            showSearch
            className="custom-input"
            style={{ width: "100%" }}
            placeholder="Select assistant user"
            optionFilterProp="children"
            onChange={handleDropdownChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {user?.map((user, index) => (
              <Option key={index} value={user.id}>
                {user.name}
              </Option>
            ))}
          </Select>
        </Modal>
      </div>
    </div>
  );
};

export default AssignOrder;
