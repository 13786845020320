import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  assistant: null,
  loading: false,
  error: null,
};

const assistantLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ASSISTANT_LOGIN:
      return {
        ...state,
        assistant: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.ASSISTANT_OTP:
      return {
        ...state,
        assistant: action.payload,
        loading: false,
        error: null,
      };
      case actionTypes.LOGOUT_ASSISTANT:
        localStorage.removeItem("profile");
        return {
          ...state,
          assistant: null,
        };

    default:
      return state;
  }
};

export default assistantLoginReducer;
