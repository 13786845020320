import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FactoryManagerLayout from "./components/factoryManagerLayout/FactoryManagerLayout";
import AssistentLayout from "./components/AssistantUser/assistentLayout/AssistantLayout";
import AssistantLogin from "./components/AssistantUser/AssistantLogin/AssistantLogin";
import OrderHistoryLayout from "./components/orderHistory/OrderHistoryLayout";
import SnaglistLayout from "./components/snaglistHistory/SnaglistLayout";
import EditOrder from "./components/AssistantUser/assignOrder/EditOrder";
import Login from "./components/login/Login";
import SignUp from "./components/signup/SignUp";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        
          <Route path="/" element={<SignUp />} /> 
          <Route path="/login" element={<Login />} />
          <Route path="/assistant-login" element={<AssistantLogin />} />
          <Route path="/factory/*" element={<FactoryManagerLayout />} />
           {/* for order and snaglist history */}
          <Route path="/my-order/:orderId" element={<OrderHistoryLayout />} />  
          <Route path="/snaglists/:snaglistId" element={<SnaglistLayout />} />
          <Route path="/assistant/*" element={<AssistentLayout />} />
          <Route path="/edit-order/:orderId" element={<EditOrder />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
