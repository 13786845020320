import React, { useEffect, useState } from "react";
import { Breadcrumb, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getSnaglistHistory } from "../../actions/history/history";

const SnaglistHistory = (props) => {
  const dispatch = useDispatch();
  const { snaglistId } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const history = useSelector((state) => state.history.snaglistHistory);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([dispatch(getSnaglistHistory(snaglistId))]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, snaglistId]);

  useEffect(() => {
    if (history) setData(history.data);
  }, [history]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Snaglist Id",
      dataIndex: "snaglistId",
      key: "snaglistId",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => {
        return createdAt ? moment(createdAt).format("DD-MM-YYYY") : "";
      },
    },
    {
      title: "Edit By",
      dataIndex: "updatedById",
      key: "updatedById",
      render: (updatedById) => (updatedById ? updatedById : "-"),
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">
          Snaglist History
        </h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            {" "}
            <a href="/factory">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {" "}
            <a href="/factory/snaglist">Snaglist</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Snaglist History</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        <div style={{ overflowX: "auto" }}>
          <Table dataSource={data} columns={columns} loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default SnaglistHistory;
