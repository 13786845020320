import * as api from "../../api";
import { GET_AU_LOCATION } from "../../constants/actionTypes";

export const getAuLocation = () => async (dispatch) => {
  try {
    const { data } = await api.getAuLocation();
    dispatch({ type: GET_AU_LOCATION, payload: data });
    return data;
  } catch (error) {
    console.log(error);
  }
};
