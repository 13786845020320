// export const UPDATE_FACTORY_MANAGER_LOGIN = 'UPDATE_FACTORY_MANAGER_LOGIN';

export const LOGIN='LOGIN';
export const VERIFY_OTP='VERIFY_OTP';
export const SIGNUP='SIGNUP';
export const LOGOUT='LOGOUT';

export const ADD_SOURCE = 'ADD_SOURCE';
export const GET_SOURCE = 'GET_SOURCE';

export const ADD_CORDINATOR_TYPE = 'ADD_CORDINATOR_TYPE';
export const GET_CORDINATOR_TYPE = 'GET_CORDINATOR_TYPE';

export const ADD_CORDINATOR = 'ADD_CORDINATOR';
export const GET_CORDINATOR = 'GET_CORDINATOR';

export const ADD_FACTORY = 'ADD_FACTORY';
export const GET_FACTORY = 'GET_FACTORY';

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const GET_PRODUCT = 'GET_PRODUCT';

export const ADD_STATUS = 'ADD_STATUS';
export const GET_STATUS = 'GET_STATUS';

export const ADD_STATUS_ACTION = 'ADD_STATUS_ACTION';
export const GET_STATUS_ACTION = 'GET_STATUS_ACTION';

export const ADD_SNAG_ACTION = 'ADD_SNAG_ACTION';
export const GET_SNAG_ACTION = 'GET_SNAG_ACTION';

export const ADD_SNAG_ISSUE = 'ADD_SNAG_ISSUE';
export const GET_SNAG_ISSUE = 'GET_SNAG_ISSUE';

export const ADD_SNAG_COST = 'ADD_SNAG_COST';
export const GET_SNAG_COST = 'GET_SNAG_COST';

export const ADD_SNAG_SOLUTION = 'ADD_SNAG_SOLUTION';
export const GET_SNAG_SOLUTION = 'GET_SNAG_SOLUTION';

export const ADD_CARCASS = 'ADD_CARCASS';
export const GET_CARCASS = 'GET_CARCASS';

export const ADD_DESIGNER = 'ADD_DESIGNER';
export const GET_DESIGNER = 'GET_DESIGNER';

 export const ADD_FINAL_SITE_SURVEYOR='ADD_FINAL_SITE_SURVEYOR';
 export const GET_FINAL_SITE_SURVEYOR ='GET_FINAL_SITE_SURVEYOR';

 export const ADD_PLANNER = 'ADD_PLANNER';
export const GET_PLANNER = 'GET_PLANNER';

export const ADD_LOCATION = 'ADD_LOCATION';
export const GET_LOCATION = 'GET_LOCATION';

export const ADD_SALES_PERSON = 'ADD_SALES_PERSON';
export const GET_SALES_PERSON = 'GET_SALES_PERSON';

export const ADD_SHUTTER = 'ADD_SHUTTER';
export const GET_SHUTTER = 'GET_SHUTTER';

export const ADD_FACTORY_ENGINEER = 'ADD_FACTORY_ENGINEER';
export const GET_FACTORY_ENGINEER = 'GET_FACTORY_ENGINEER';

export const ADD_ROLE_ACCESS = 'ADD_ROLE_ACCESS';
export const GET_ROLE_ACCESS = 'GET_ROLE_ACCESS';

export const ADD_ASSISTANT_USER = 'ADD_ASSISTANT_USER';
export const GET_ASSISTANT_USER = 'GET_ASSISTANT_USER';
export const LOGOUT_ASSISTANT='LOGOUT_ASSISTANT';

export const ADD_ENQUIRY = 'ADD_ENQUIRY';
export const GET_ENQUIRY = 'GET_ENQUIRY';

export const ADD_ORDER = 'ADD_ORDER';
export const GET_ORDER = 'GET_ORDER';

export const GET_ORDER_COUNT = 'GET_ORDER_COUNT';
export const GET_ENQUIRY_COUNT = 'GET_ENQUIRY_COUNT';
export const GET_ORDERLIST_COUNT = 'GET_ORDERLIST_COUNT';

export const ADD_SNAGLIST='ADD_SNAGLIST';
export const GET_SNAGLIST='GET_SNAGLIST';

export const ADD_ORDERLIST='ADD_ORDERLIST';
export const GET_ORDERLIST='GET_ORDERLIST';
export const GET_ORDERLIST_BY_ID='GET_ORDERLIST_BY_ID';

export const GET_ORDER_HISTORY='GET_ORDER_HISTORY';
export const GET_SNAGLIST_HISTORY='GET_SNAGLIST_HISTORY';

export const ASSIGN_ORDER='ASSIGN_OREDER';

export const UPDATE_FACTORY_NAME='UPDATE_FACTORY_NAME';
export const UPDATE_LOGO='UPDATE_LOGO';
export const UPDATE_FONT_SIZE='UPDATE_FONT_SIZE';
export const UPDATE_NOTIFICATION='UPDATE_NOTIFICATION';
export const UPDATE_COLOR='UPDATE_COLOR';
export const GET_SETTING='GET_SETTING';

export const ASSISTANT_OTP='ASSISTANT_OTP';
export const ASSISTANT_LOGIN='ASSISTANT_LOGIN';
export const GET_ASSISTANT_ACCESS='GET_ASSISTANT_ACCESS';
export const GET_ASSISTANT_ASSIGN_ORDER='GET_ASSISTANT_ASSIGN_ORDER';

export const UPDATE_AU_COLOR='UPDATE_AU_COLOR';
export const UPDATE_AU_FONT_SIZE='UPDATE_AU_FONT_SIZE';
export const UPDATE_AU_NOTIFICATION='UPDATE_AU_NOTIFICATION';
export const GET_AU_SETTING='GET_AU_SETTING';

export const GET_AU_LOCATION='GET_AU_LOCATION';
export const GET_AU_PRODUCT='GET_AU_PRODUCT';
export const GET_AU_STATUS='GET_AU_STATUS';
export const UPDATE_ORDER='UPDATE_ORDER';
export const GET_DB_ASSIGN_ORDER='GET_DB_ASSIGN_ORDER';
