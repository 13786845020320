import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spin } from "antd";
import { Breadcrumb } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getEnquiryCount,
  getOrderCount,
  getOrderlistCount,
} from "../../actions/dashboard/dashboard";

const cardStyle = {
  marginBottom: "10px",
  padding: "24px",
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const orderCount = useSelector((state) => state.dashboard.orderCount);
  const enquiryCount = useSelector((state) => state.dashboard.enquiryCount);
  const orderlist = useSelector((state) => state.dashboard.orderlistCount);

  // console.log(orderCount);
  // console.log(enquiryCount)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        await Promise.all([
          dispatch(getOrderCount("open")),
          dispatch(getEnquiryCount("active")),
          dispatch(getOrderlistCount("active")),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  return (
    <>
      <div className="flex justify-between items-center pl-5 pr-5 pt-5">
        <h2 className="text-lg font-semibold  text-gray-800 font-poppins">
          Dashboard
        </h2>
        <Breadcrumb className="font-poppins">
          <Breadcrumb.Item>
            {" "}
            <a href="/factory">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        <Spin spinning={loading}>
          <div style={{ padding: "20px" }}>
            <h3 style={{fontSize:'15px'}}>Hii Welcome back</h3>
            <Row gutter={16} justify="start">
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Total Orders"
                  bordered={false}
                  style={{ background: "#fff2cd", ...cardStyle }}
                >
                  {orderCount?.data}
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Total Enquiries"
                  bordered={false}
                  style={{ background: "#feede7", ...cardStyle }}
                >
                  {enquiryCount?.enquiryCount}
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Total Orderlists"
                  bordered={false}
                  style={{ background: "#f9d9f9", ...cardStyle }}
                >
                  {orderlist?.data}
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Furnishi Orders"
                  bordered={false}
                  style={{ background: "#fff2cd", ...cardStyle }}
                >
                  4
                </Card>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Running Complaints"
                  bordered={false}
                  style={{ background: "#fff2cd", ...cardStyle }}
                >
                  8
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Next 15 Days Service Schedule"
                  bordered={false}
                  style={{ background: "#dbf9f0", ...cardStyle }}
                >
                  6
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Services Assigned To Furnishi"
                  bordered={false}
                  style={{ background: "#f9d9f9", ...cardStyle }}
                >
                  4
                </Card>
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default Dashboard;
