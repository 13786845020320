import * as api from "../../api";
import { ASSISTANT_LOGIN,ASSISTANT_OTP } from "../../constants/actionTypes";

export const assistantLogin = (userInfo) => async (dispatch) => {
  try {
    const { data } = await api.assistantLogin(userInfo);
    dispatch({ type: ASSISTANT_LOGIN, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const assistantOtp = (verifyOtp) => async (dispatch) => {
  try {
    const { data } = await api.assistantOtp(verifyOtp);
    const { accessToken, refreshToken } = data;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    dispatch({ type: ASSISTANT_OTP, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
