
import * as api from "../../api";
import {
  UPDATE_FACTORY_NAME,
  UPDATE_LOGO,
  UPDATE_FONT_SIZE,
  UPDATE_NOTIFICATION,
  UPDATE_COLOR,
  GET_SETTING,
} from "../../constants/actionTypes";

export const factoryName = (factoryName) => async (dispatch) => {
  try {
    const { data } = await api.factoryName(factoryName);
    dispatch({ type: UPDATE_FACTORY_NAME, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateLogo = (formData) => async (dispatch) => {
  try {
    const { data } = await api.updateLogo(formData);
    dispatch({ type: UPDATE_LOGO, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateFontSize = (fontSize) => async (dispatch) => {
  try {
    const { data } = await api.updateFontSize(fontSize);
    dispatch({ type: UPDATE_FONT_SIZE, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateNotification = (notification) => async (dispatch) => {
  try {
    const { data } = await api.updateNotification(notification);
    dispatch({ type: UPDATE_NOTIFICATION, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateColor = (colorInfo) => async (dispatch) => {
  try {
    const { data } = await api.updateColor(colorInfo);
    dispatch({ type: UPDATE_COLOR, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getSetting = () => async (dispatch) => {
  try {
    const { data } = await api.getSetting();
    dispatch({ type: GET_SETTING, payload: data });
    return data;
  } catch (error) {
    console.log(error);
  }
};
