import React, { useState } from "react";
import { Form, Input, Button, message, Col, Row, Carousel } from "antd";
import { signup } from "../../actions/fmLogin/auth";
import image1 from "../../assets/img/image1.jpg";
import image2 from "../../assets/img/image2.webp";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        emailId: values.emailId,
        mobileNumber: values.mobileNumber,
        password: values.password,
        role: "factoryManager",
      };
      console.log("Received values:", data);
      setLoading(true);
      const res = await dispatch(signup(data));

      message.success(res.message);
      navigate("/login");
    } catch (error) {
      console.error("Error updating login:", error);
      //   message.error(error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center card-bg">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row w-10/12 lg:w-9/12 bg-white rounded-xl mx-auto shadow-lg overflow-hidden">
          <div
            className="w-full lg:w-1/2 flex flex-col items-center justify-center bg-no-repeat bg-cover bg-center hidden md:block"
            style={{ height: "100%" }}
          >
            <Carousel
              autoplay
              autoplaySpeed={2000}
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ height: "100%" }}>
                <img
                  src={image1}
                  alt="Carousel 1"
                  style={{
                    width: "100%",
                    height: "80vh",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div style={{ height: "100%" }}>
                <img
                  src={image2}
                  alt="Carousel 2"
                  style={{
                    width: "100%",
                    height: "80vh",
                    objectFit: "cover",
                  }}
                />
              </div>
            </Carousel>
          </div>
          <div className="w-full lg:w-1/2 py-8 px-12">
            <h2 className="mb-8 "><span className="text-lg">Welcome to</span> <br/> <span className="text-3xl text-bold">Furnishi Services</span></h2>
            <Form
              onFinish={onFinish}
              initialValues={{ remember: true }}
              name="myForm"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="emailId"
                rules={[
                  { required: true, message: "Please input your email!" },
                  {
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="mobileNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your mobile number!",
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit mobile number!",
                  },
                ]}
              >
                <Input placeholder="Mobile Number" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Button
                 className="default-btn w-full"
                  htmlType="submit"
                  loading={loading}
                >
                  Sign up
                </Button>
              </Form.Item>
            </Form>

            <div className="text-center">
              Already have an account? <a href="/login">Login</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
