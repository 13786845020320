import * as api from "../../api";
import {GET_ASSISTANT_ACCESS} from "../../constants/actionTypes";

export const getAssistantAccess= () => async (dispatch) => {
    try {
      const { data } = await api.getAssistantAccess();
      dispatch({ type: GET_ASSISTANT_ACCESS, payload: data });
      return data;
    } catch (error) {
      console.log(error);
    }
  };