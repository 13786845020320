import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
  message,
} from "antd";
import { useDispatch } from "react-redux";
import { addSnaglist } from "../../../actions/snaglist/snaglist";

const { Option } = Select;

const NewSnaglist = (props) => {
  const {
    product,
    status,
    location,
    snagAction,
    snagCost,
    snagIssue,
    snagSolution,
  } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading,setLoading]=useState(false);
  const [image, setImage] = useState(null);
  const [photoFile, setPhotoFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [targetStartDate, setTargetStartDate] = useState(null);
  const [targetEndDate, setTargetEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [products, setProducts] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [action, setAction] = useState([]);
  const [cost, setCost] = useState([]);
  const [issue, setIssue] = useState([]);
  const [solution, setSolution] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [sourceCordinatorData, setSourceCordinatorData] = useState([]);
  const [factoryCordinatorData, setFactoryCordinatorData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [factoryCordinatorNumber, setFactoryCordinatorNumber] = useState('');
  const [sourceCordinatorNumber, setSourceCordinatorNumber] = useState('');
  const [customerCordinatorNumber, setCustomerCordinatorNumber] = useState('');

  const handleLocationChange = (value) => {
    const selectedLocation = locations?.find(
      (location) => location.id === value
    );
    // console.log(selectedLocation.pincode);
    setSelectedLocation(selectedLocation.pincode || "");
  };

  const api = axios.create({
    baseURL: "https://furnishi-server-test.onrender.com",
  });

  api.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      if (refreshToken) {
        config.headers["Refresh-Token"] = `Bearer ${refreshToken}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    const fetchCordinatorData = async (cordinatorTypeId, setDataFunction) => {
      try {
        const response = await api.get(
          `/fm/cordinator?cordinatorTypeId=${cordinatorTypeId}`
        );
        setDataFunction(response.data.data);
      } catch (error) {
        console.error(
          `Error fetching ${
            cordinatorTypeId === 2 ? "Source" : "Factory"
          } Cordinator data:`,
          error
        );
      }
    };

    fetchCordinatorData(1, setCustomerData);
    fetchCordinatorData(2, setSourceCordinatorData);
    fetchCordinatorData(3, setFactoryCordinatorData);
  }, []);

  useEffect(() => {
    if (
      status &&
      product &&
      location &&
      snagCost &&
      snagIssue &&
      snagSolution &&
      snagAction
    ) {
      setProducts(product.data);
      setStatusData(status.data);
      setLocations(location.data);
      setCost(snagCost.data);
      setSolution(snagSolution.data);
      setIssue(snagIssue.data);
      setAction(snagAction.data);
    }
  }, [
    status,
    product,
    location,
    snagCost,
    snagIssue,
    snagSolution,
    snagAction,
  ]);

  // const formDataToObject = (formData) => {
  //   const object = {};
  //   formData.forEach((value, key) => {
  //     object[key] = value;
  //   });
  //   return object;
  // };
  const onFinish = async (values) => {
    try {
      // console.log("received values", values.attachment);
      setLoading(true);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("mobileNumber", values.mobileNumber);
      formData.append("address", values.address);
      formData.append("locationId", values.locationId);
      formData.append("customerCordinatorId", values.customerCordinatorId);
      formData.append("sourceCordinatorId", values.sourceCordinatorId);
      formData.append("factoryCordinatorId", values.factoryCordinatorId);
      formData.append("productId", values.productId);
      formData.append("saleValue", values.saleValue);
      formData.append("materialValue", values.materialValue);
      formData.append("faceArea", values.faceArea);
      formData.append("targetStartDate", targetStartDate);
      formData.append("targetEndDate", targetEndDate);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      formData.append("totalService", values.totalService);
      formData.append("serviceDone", values.serviceDone);
      formData.append("servicePending", values.servicePending);
      formData.append("serviceCalender", values.serviceCalendar);
      formData.append("estimatedCost", values.estimatedCost);
      formData.append("actualCost", values.actualCost);
      formData.append("expenseTillDate", values.expenseTillDate);
      formData.append("statusId", values.statusId);
      formData.append("reason", values.reason);
      formData.append(
        "estimatedQuoteAfterDiscount",
        values.estimatedQuoteAfterDiscount
      );
      formData.append("attachmentFile", image);
      formData.append("photoFile", photoFile);
      formData.append("videoFile", videoFile);
      formData.append("snagCostId", values.snagCostId);
      formData.append("snagSolutionId", values.snagSolutionId);
      formData.append("snagIssueId", values.snagIssueId);
      formData.append("snagActionId", values.snagActionId);
      // const formDataObject = formDataToObject(formData);

      // console.log("Form Data:", formDataObject);
      const response = await dispatch(addSnaglist(formData));

      if (response.success) {
        message.success(response.message);
        form.resetFields();
        setTargetStartDate(null);
        setTargetEndDate(null);
        setStartDate(null);
        setEndDate(null);
        setImage(null);
        setPhotoFile(null);
        setVideoFile(null);
        setSourceCordinatorNumber("");
        setSelectedLocation("");
        setFactoryCordinatorNumber("");
        setCustomerCordinatorNumber("");
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error(" My oder Error:", error);
      message.error(error.response?.data?.message || "An error occured");
    }finally{
      setLoading(false);
    }
  };
  const validateContactNumber = (_, value) => {
    const regex = /^\d{10}$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit contact number!");
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const fileName = event.target.files[0];
      setImage(fileName);
    }
  };

  const onPicChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const fileName = event.target.files[0];
      setPhotoFile(fileName);
    }
  };
  const onVideoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const fileName = event.target.files[0];
      setVideoFile(fileName);
    }
  };

  
  const handleFactoryCoordinatorChange = (value) => {
    const selectedFactory = factoryCordinatorData.find((factory) => factory.cordinatorTypeId === value);
    setFactoryCordinatorNumber(selectedFactory?.number || '');
  };

  const handleSourceCoordinatorChange = (value) => {
    const selectedSource = sourceCordinatorData.find((source) => source.cordinatorTypeId === value);
    setSourceCordinatorNumber(selectedSource?.number || '');
  };

  const handleCustomerCoordinatorChange = (value) => {
    const selectedCustomer = customerData.find((customer) => customer.cordinatorTypeId === value);
    setCustomerCordinatorNumber(selectedCustomer?.number || '');
  };

  console.log(solution)

  return (
    <Form
      form={form}
      onFinish={onFinish}
      name="myForm"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please enter name!" }]}
          >
            <Input placeholder="Name" className="custom-input" />
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Mobile Number"
            name="mobileNumber"
            rules={[
              { required: true, message: "Please enter mobile number!" },
              { validator: validateContactNumber },
            ]}
          >
            <Input placeholder="Mobile Number" className="custom-input" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: "Please enter address!" }]}
          >
            <Input placeholder="Address" className="custom-input" />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Location"
            name="locationId"
            rules={[{ required: true, message: "Please select location!" }]}
          >
            <Select placeholder="Location" onChange={handleLocationChange} className="custom-input">
              {locations?.map((location) => (
                <Option key={location.id} value={location.id}>
                  {location.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {selectedLocation && (
        <Row gutter={16}>
          <Col lg={12} sm={24} xs={24} md={12}>
            <Form.Item label="Pincode">
              <Input placeholder="Pin Code" value={selectedLocation} disabled className="custom-input" />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Customer Cordinator"
            name="customerCordinatorId"
            rules={[
              { required: true, message: "Please select customer cordinator!" },
            ]}
          >
            <Select placeholder="Customer Cordinator"  onChange={handleCustomerCoordinatorChange} className="custom-input">
              {customerData?.map((customer) => (
                <Option key={customer.cordinatorTypeId} value={customer.cordinatorTypeId}>
                  {customer.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item label="Customer Cordinator Number">
            <Input placeholder="Number" disabled value={customerCordinatorNumber} className="custom-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Source Cordinator"
            name="sourceCordinatorId"
            rules={[
              { required: true, message: "Please select source cordinator!" },
            ]}
          >
            <Select placeholder="Source Cordinator"  onChange={handleSourceCoordinatorChange} className="custom-input">
              {sourceCordinatorData?.map((source) => (
                <Option key={source.cordinatorTypeId} value={source.cordinatorTypeId}>
                  {source.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item label="Source Cordinator Number">
            <Input placeholder="Number" value={sourceCordinatorNumber} disabled className="custom-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Factory Cordinator"
            name="factoryCordinatorId"
            rules={[
              { required: true, message: "Please select factory cordinator!" },
            ]}
          >
            <Select placeholder="Factory Cordinator"   onChange={handleFactoryCoordinatorChange} className="custom-input">
              {factoryCordinatorData?.map((factory) => (
                <Option key={factory.cordinatorTypeId} value={factory.cordinatorTypeId}>
                  {factory.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item label="Factory Cordinator Number">
            <Input placeholder="Number" disabled value={factoryCordinatorNumber} className="custom-input" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Product"
            name="productId"
            rules={[{ required: true, message: "Please select product!" }]}
          >
            <Select placeholder="Product" className="custom-input">
              {products?.map((product) => (
                <Option key={product.id} value={product.id}>
                  {product.product}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Sale Value"
            name="saleValue"
            rules={[{ required: true, message: "Please enter sale value!" }]}
          >
            <Input placeholder="Sale Value" className="custom-input" />
          </Form.Item>
        </Col>
      </Row>
     

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Material Value"
            name="materialValue"
            rules={[
              { required: true, message: "Please enter material value!" },
            ]}
          >
            <Input placeholder="Material Value" className="custom-input" />
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Face Area"
            name="faceArea"
            rules={[{ required: true, message: "Please enter face area!" }]}
          >
            <Input placeholder="Face Area" className="custom-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Target Start Date"
            rules={[
              { required: true, message: "Please select target start date!" },
            ]}
          >
            <DatePicker
            className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setTargetStartDate(dateString);
              }}
              format="MM/DD/YYYY"
            />
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Target End Date"
            rules={[{ required: true, message: "Please enter date!" }]}
          >
            <DatePicker
            className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setTargetEndDate(dateString);
              }}
              format="MM/DD/YYYY"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Start Date"
            rules={[{ required: true, message: "Please enter date!" }]}
          >
            <DatePicker
            className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setStartDate(dateString);
              }}
              format="MM/DD/YYYY"
            />
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="End Date"
            rules={[{ required: true, message: "Please enter date!" }]}
          >
            <DatePicker
            className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setEndDate(dateString);
              }}
              format="MM/DD/YYYY"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Issue"
            name="snagIssueId"
            rules={[{ required: true, message: "Please select issue!" }]}
          >
            <Select placeholder="Issue" className="custom-input">
              {issue?.map((issue) => (
                <Option key={issue.id} value={issue.id}>
                  {issue.issue}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Reason"
            name="reason"
            rules={[{ required: true, message: "Please enter reason" }]}
          >
            <Input placeholder="Reason" className="custom-input" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Solution"
            name="snagSolutionId"
            rules={[{ required: true, message: "Please select solution!" }]}
          >
            <Select placeholder="Solution" className="custom-input">
              {solution?.map((solution) => (
                <Option key={solution.id} value={solution.id}>
                  {solution.solution}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Action"
            name="snagActionId"
            rules={[{ required: true, message: "Please select action!" }]}
          >
            <Select placeholder="Action" className="custom-input">
              {action?.map((action) => (
                <Option key={action.id} value={action.id}>
                  {action.action}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Cost"
            name="snagCostId"
            rules={[{ required: true, message: "Please select cost!" }]}
          >
            <Select placeholder="Cost" className="custom-input">
              {cost?.map((cost) => (
                <Option key={cost.id} value={cost.id}>
                  {cost.cost}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Attachment"
            name="attachmentFile"
            rules={[{ required: true, message: "Please select attachment!" }]}
          >
            <Input type="file" onChange={onImageChange} className="filetype custom-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Pic"
            name="photoFIle"
            rules={[{ required: true, message: "Please enter pic!" }]}
          >
            <Input type="file" onChange={onPicChange} className="filetype custom-input" />
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Video"
            name="videoFile"
            rules={[{ required: true, message: "Please enter video!" }]}
          >
            <Input type="file" onChange={onVideoChange} className="filetype custom-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Total Service"
            name="totalService"
            rules={[
              { required: true, message: "Please enter total services!" },
            ]}
          >
            <Input placeholder="Total Service" type="number" className="custom-input" />
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Service Done"
            name="serviceDone"
            rules={[{ required: true, message: "Please enter service done!" }]}
          >
            <Input placeholder="Service Done" type="number" className="custom-input" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Service Pending"
            name="servicePending"
            rules={[
              { required: true, message: "Please enter service pending!" },
            ]}
          >
            <Input placeholder="Service Pending" type="number" className="custom-input" />
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Service Calendar"
            name="serviceCalendar"
            rules={[
              { required: true, message: "Please enter service calendar!" },
            ]}
          >
            <Input placeholder="Service Calendar" type="number" className="custom-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Estimated Cost"
            name="estimatedCost"
            rules={[
              { required: true, message: "Please enter estimated cost!" },
            ]}
          >
            <Input placeholder="Estimated Cost" type="number" className="custom-input" />
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Actual Cost"
            name="actualCost"
            rules={[{ required: true, message: "Please enter actual cost!" }]}
          >
            <Input placeholder="Actual Cost" type="number" className="custom-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Expense Till Date"
            name="expenseTillDate"
            rules={[
              { required: true, message: "Please enter expense till date!" },
            ]}
          >
            <Input placeholder="Expense Till Date" type="number" className="custom-input" />
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Estimated Quote after Discount"
            name="estimatedQuoteAfterDiscount"
            rules={[
              {
                required: true,
                message: "Please enter estimated quote after discount!",
              },
            ]}
          >
            <Input placeholder="Estimated Quote after Discount" type="number" className="custom-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
       
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Status"
            name="statusId"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select placeholder="Status" className="custom-input">
              {statusData?.map((status) => (
                <Option key={status.id} value={status.id}>
                  {status.status}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button className="default-btn" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NewSnaglist;
