import React, { useEffect, useState } from "react";
import { Breadcrumb,  Card, Spin,Timeline } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getOrderHistory } from "../../actions/history/history";

const OrderHistory = (props) => {
  const dispatch = useDispatch();
  const { orderId } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const orderHistory = useSelector((state) => state.history.orderHistory);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([dispatch(getOrderHistory(orderId))]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, orderId]);

  useEffect(() => {
    if (orderHistory) setData(orderHistory.data);
  }, [orderHistory]);

 

  return (
    <>
    <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Order History</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            {" "}
            <a href="/factory">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {" "}
            <a href="/factory/my-orders">My Orders</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Order History</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        <Spin spinning={loading}>
          <Card style={{ padding: "24px" }}>
            <Timeline>
              {data?.map((item) => (
                <Timeline.Item key={item.id}>
                  <p>{`Order #${item.orderId}`}</p>
                  <p>{`Created by: ${item.updatedBy?.firstName} ${item.updatedBy?.lastName} at ${moment(item.createdAt).format("DD-MM-YYYY")}`}</p>
                  <p>{`Updated by: ${item.updatedBy?.firstName} ${item.updatedBy?.lastName} at ${moment(item.updatedAt).format("DD-MM-YYYY")}`}</p>
                </Timeline.Item>
              ))}
            </Timeline>
          </Card>
        </Spin>
      </div>
    
    
    </>
  );
};

export default OrderHistory;
