import React from "react";
import { Breadcrumb, Tabs } from "antd";
import FactoryName from "./FactoryName";
import FontSize from "./FontSize";
import Logo from "./Logo";
import Notification from "./Notification";
import Color from "./Color";

const { TabPane } = Tabs;

const Settings = () => {
  return (
    <>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Settings</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            {" "}
            <a href="/factory">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Settings</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
      
          <Tabs defaultActiveKey="1">
            <TabPane tab="Factory Name" key="1">
              <FactoryName />
            </TabPane>
            <TabPane tab="Logo" key="2">
              <Logo />
            </TabPane>
            <TabPane tab="Font Size" key="3">
              <FontSize />
            </TabPane>
            <TabPane tab="Color" key="4">
              <Color />
            </TabPane>
            <TabPane tab="Notification" key="5">
              <Notification />
            </TabPane>
          </Tabs>
      
      </div>
    </>
  );
};

export default Settings;
