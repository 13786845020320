import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  TimePicker,
  message,
  Button,
  Row,
  Col,
} from "antd";
import axios from "axios";
import { addOrderList } from "../../../actions/orderlist/orderlist";
import { useDispatch } from "react-redux";

const { Option } = Select;

const NewOrderList = (props) => {
  const {
    product,
    status,
    location,
    carcass,
    shutter,
    designer,
    salesPerson,
    siteSurveyor,
    source,
    factoryEngineer,
  } = props;
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(false);
  const [receivedDate, setReceivedDate] = useState(null);
  const [targetDate, setTargetDate] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [sourceCordinatorData, setSourceCordinatorData] = useState([]);
  const [factoryCordinatorData, setFactoryCordinatorData] = useState([]);
  const [factoryCordinatorNumber, setFactoryCordinatorNumber] = useState("");
  const [sourceCordinatorNumber, setSourceCordinatorNumber] = useState("");
  const [customerCordinatorNumber, setCustomerCordinatorNumber] = useState("");
  const [products, setProducts] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [carcassData, setCarcassData] = useState([]);
  const [designerData, setDesignerData] = useState([]);
  const [shutterData, setShutterData] = useState([]);
  const [siteSurveyors, setSiteSurveyors] = useState([]);
  const [salesPersons, setSalesPersons] = useState([]);
  const [sources, setSources] = useState([]);
  const [factoryEngineers, setFactoryEngineers] = useState([]);
  const [dispatchDate, setDispatchDate] = useState(null);
  const [assembly, setAssembly] = useState(null);
  const [cleaning, setCleaning] = useState(null);
  const [packing, setPacking] = useState(null);
  const [panelProduction, setPanelProduction] = useState(null);
  const [designClearance, setDesignClearance] = useState(null);
  const [accountClearance, setAccountClearance] = useState(null);
  const [shopDocuments, setShopDocuments] = useState(null);
  const [stockCheck, setStockCheck] = useState(null);
  const [poPrepare, setPOPrepare] = useState(null);
  const [poRelease, setPORelease] = useState(null);
  const [poApproval, setPOApproval] = useState(null);
  const [jobWorkDone, setJobWorkDone] = useState(null);
  const [otherMaterialProduction, setOtherMaterialProduction] = useState(null);
  const [otherMaterialAvailable, setOtherMaterialAvailable] = useState(null);
  const [rawMaterialAvailable, setRawMaterialAvailable] = useState(null);
  const [paintMaterialProduction, setPaintMaterialProduction] = useState(null);
  const [indentRelease, setIndentRelease] = useState(null);
  const [workStartTime, setWorkStartTime] = useState(null);
  const [workEndTime, setWorkEndTime] = useState(null);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      // console.log("received values", values.attachment);
      setLoading(true);
      const data = {
        receivedDate: receivedDate,
        targetDate: targetDate,
        customerName: values.customerName,
        customerNumber: values.customerNumber,
        siteAddress: values.siteAddress,
        sitePincode: values.sitePincode,
        siteGoogleLocation: values.siteGoogleLocation,
        sourceId: values.sourceId,
        customerCordinatorId: values.customerCordinatorId,
        sourceCordinatorId: values.sourceCordinatorId,
        factoryCordinatorId: values.factoryCordinatorId,
        productId: values.productId,
        locationId: values.locationId,
        noOfServices: values.noOfServices,
        area: values.area,
        orderValue: values.orderValue,
        paymentReceived: values.paymentReceived,
        statusId: values.statusId,
        carcassId: values.carcassId,
        shutterId: values.shutterId,
        designerId: values.designerId,
        siteSurveyorId: values.siteSurveyorId,
        salesPersonId: values.salesPersonId,
        factoryEngineerId: values.factoryEngineerId,
        workStartTime: workStartTime,
        workEndTime: workEndTime,
        indentNumber: values.indentNumber,
        indentRelease: indentRelease,
        designClearance: designClearance,
        accountClearance: accountClearance,
        shopDocuments: shopDocuments,
        stockCheck: stockCheck,
        poPrepare: poPrepare,
        poApproval: poApproval,
        poRelease: poRelease,
        jobWorkDone: jobWorkDone,
        rawMaterialAvailable: rawMaterialAvailable,
        otherMaterialAvailable: otherMaterialAvailable,
        paintMaterialProduction: paintMaterialProduction,
        otherMaterialProduction: otherMaterialProduction,
        panelProduction: panelProduction,
        assembly: assembly,
        cleaning: cleaning,
        packing: packing,
        dispatch: dispatchDate,
      };
      // console.log("Form Data:", data);
      const response = await dispatch(addOrderList(data));

      if (response.success) {
        message.success(response.message);
        form.resetFields();
        setTargetDate(null);
        setReceivedDate(null);
        setSourceCordinatorNumber("");
        setFactoryCordinatorNumber("");
        setCustomerCordinatorNumber("");
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error("Orderlist Error:", error);
      message.error(error.response?.data?.message || "An error occured");
    }finally{
      setLoading(false);
    }
  };

  const validateContactNumber = (_, value) => {
    const regex = /^\d{10}$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit contact number!");
  };

  const api = axios.create({
    baseURL: "https://furnishi-server-test.onrender.com",
  });

  api.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      if (refreshToken) {
        config.headers["Refresh-Token"] = `Bearer ${refreshToken}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    const fetchCordinatorData = async (cordinatorTypeId, setDataFunction) => {
      try {
        const response = await api.get(
          `/fm/cordinator?cordinatorTypeId=${cordinatorTypeId}`
        );
        setDataFunction(response.data.data);
      } catch (error) {
        console.error(
          `Error fetching ${
            cordinatorTypeId === 2 ? "Source" : "Factory"
          } Cordinator data:`,
          error
        );
      }
    };

    fetchCordinatorData(1, setCustomerData);
    fetchCordinatorData(2, setSourceCordinatorData);
    fetchCordinatorData(3, setFactoryCordinatorData);
  }, []);

  useEffect(() => {
    if (
      status &&
      product &&
      location &&
      designer &&
      carcass &&
      shutter &&
      salesPerson &&
      siteSurveyor &&
      source &&
      factoryEngineer
    ) {
      setProducts(product.data);
      setStatusData(status.data);
      setLocations(location.data);
      setDesignerData(designer.data);
      setCarcassData(carcass.data);
      setShutterData(shutter.data);
      setSources(source.data);
      setSalesPersons(salesPerson.data);
      setSiteSurveyors(siteSurveyor.data);
      setFactoryEngineers(factoryEngineer.data);
    }
  }, [
    status,
    product,
    location,
    shutter,
    carcass,
    designer,
    source,
    salesPerson,
    siteSurveyor,
    factoryEngineer,
  ]);

  const handleFactoryCoordinatorChange = (value) => {
    const selectedFactory = factoryCordinatorData.find(
      (factory) => factory.cordinatorTypeId === value
    );
    setFactoryCordinatorNumber(selectedFactory?.number || "");
  };

  const handleSourceCoordinatorChange = (value) => {
    const selectedSource = sourceCordinatorData.find(
      (source) => source.cordinatorTypeId === value
    );
    setSourceCordinatorNumber(selectedSource?.number || "");
  };

  const handleCustomerCoordinatorChange = (value) => {
    const selectedCustomer = customerData.find(
      (customer) => customer.cordinatorTypeId === value
    );
    setCustomerCordinatorNumber(selectedCustomer?.number || "");
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      name="myForm"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Received Date"
            name="receivedDate"
            rules={[
              { required: true, message: "Please select received date!" },
            ]}
          >
            <DatePicker
            className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setReceivedDate(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Target Date"
            name="targetDate"
            rules={[{ required: true, message: "Please select target date!" }]}
          >
            <DatePicker
            className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setTargetDate(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Customer Name"
            name="customerName"
            rules={[{ required: true, message: "Please enter customer name!" }]}
          >
            <Input placeholder="Customer Name" className="custom-input" />
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Customer Number"
            name="customerNumber"
            rules={[
              { required: true, message: "Please enter customer number!" },
              // { validator: validateContactNumber },
            ]}
          >
            <Input placeholder="Customer Number" className="custom-input" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Site Address"
            name="siteAddress"
            rules={[{ required: true, message: "Please enter site address!" }]}
          >
            <Input placeholder="Site Address" className="custom-input" />
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Site Pincode"
            name="sitePincode"
            rules={[{ required: true, message: "Please enter site pincode!" }]}
          >
            <Input placeholder="Site Pincode" className="custom-input" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Site Google Location"
            name="siteGoogleLocation"
            rules={[
              { required: true, message: "Please enter site google location!" },
            ]}
          >
            <Input
              className="custom-input"
              placeholder="Site Google Location"
            />
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Source"
            name="sourceId"
            rules={[{ required: true, message: "Please select source!" }]}
          >
            <Select placeholder="Source" className="custom-input">
              {sources?.map((source) => (
                <Option key={source.source} value={source.id}>
                  {source.source}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Customer Cordinator"
            name="customerCordinatorId"
            rules={[
              { required: true, message: "Please select customer cordinator!" },
            ]}
          >
            <Select
              placeholder="Customer Cordinator"
              onChange={handleCustomerCoordinatorChange}
              className="custom-input"
            >
              {customerData?.map((customer, index) => (
                <Option key={index} value={customer.cordinatorTypeId}>
                  {customer.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item label="Customer Cordinator Number">
            <Input
              placeholder="Number"
              disabled
              value={customerCordinatorNumber}
              className="custom-input"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Source Cordinator"
            name="sourceCordinatorId"
            rules={[
              { required: true, message: "Please select source cordinator!" },
            ]}
          >
            <Select
              className="custom-input"
              placeholder="Source Cordinator"
              onChange={handleSourceCoordinatorChange}
            >
              {sourceCordinatorData?.map((source, index) => (
                <Option key={index} value={source.cordinatorTypeId}>
                  {source.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item label="Source Cordinator Number">
            <Input
              className="custom-input"
              placeholder="Number"
              value={sourceCordinatorNumber}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Factory Cordinator"
            name="factoryCordinatorId"
            rules={[
              { required: true, message: "Please select factory cordinator!" },
            ]}
          >
            <Select
              placeholder="Factory Cordinator"
              onChange={handleFactoryCoordinatorChange}
              className="custom-input"
            >
              {factoryCordinatorData?.map((factory, index) => (
                <Option key={index} value={factory.cordinatorTypeId}>
                  {factory.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item label="Factory Cordinator Number">
            <Input
              className="custom-input"
              placeholder="Number"
              disabled
              value={factoryCordinatorNumber}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Product"
            name="productId"
            rules={[{ required: true, message: "Please select product!" }]}
          >
            <Select placeholder="Product" className="custom-input">
              {products?.map((product, index) => (
                <Option key={index} value={product.id}>
                  {product.product}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Location"
            name="locationId"
            rules={[{ required: true, message: "Please select location!" }]}
          >
            <Select placeholder="Location" className="custom-input">
              {locations?.map((location, index) => (
                <Option key={index} value={location.id}>
                  {location.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="No Of Services"
            name="noOfServices"
            rules={[
              { required: true, message: "Please enter number of services!" },
            ]}
          >
            <Input placeholder="No Of Services" className="custom-input" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Area (Sqft)"
            name="area"
            rules={[{ required: true, message: "Please enter area!" }]}
          >
            <Input placeholder="Area" className="custom-input" />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Order Value"
            name="orderValue"
            rules={[{ required: true, message: "Please enter order value!" }]}
          >
            <Input placeholder="Order Value" className="custom-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Payment Received"
            name="paymentReceived"
            rules={[
              { required: true, message: "Please enter payment received!" },
            ]}
          >
            <Input placeholder="Payment Received" className="custom-input" />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Status"
            name="statusId"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select placeholder="Status" className="custom-input">
              {statusData?.map((status, index) => (
                <Option key={index} value={status.id}>
                  {status.status}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Carcass"
            name="carcassId"
            rules={[{ required: true, message: "Please select carcass!" }]}
          >
            <Select placeholder="Carcass" className="custom-input">
              {carcassData?.map((carcass, index) => (
                <Option key={index} value={carcass.id}>
                  {carcass.carcass}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Shutter"
            name="shutterId"
            rules={[{ required: true, message: "Please select shutter!" }]}
          >
            <Select placeholder="Shutter" className="custom-input">
              {shutterData?.map((shutter, index) => (
                <Option key={index} value={shutter.id}>
                  {shutter.shutter}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Designer"
            name="designerId"
            rules={[{ required: true, message: "Please select designer!" }]}
          >
            <Select placeholder="Designer" className="custom-input">
              {designerData?.map((designer, index) => (
                <Option key={index} value={designer.id}>
                  {designer.designer}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Final Site Surveyor"
            name="siteSurveyorId"
            rules={[
              { required: true, message: "Please select final site surveyor!" },
            ]}
          >
            <Select placeholder="Final Site Surveyor" className="custom-input">
              {siteSurveyors?.map((siteSurveyor, index) => (
                <Option key={index} value={siteSurveyor.id}>
                  {siteSurveyor.siteSurveyor}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Sales Person"
            name="salesPersonId"
            rules={[{ required: true, message: "Please select sales person!" }]}
          >
            <Select placeholder="Sales Person" className="custom-input">
              {salesPersons?.map((salesPerson, index) => (
                <Option key={index} value={salesPerson.id}>
                  {salesPerson.salesPerson}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Factory Engineer"
            name="factoryEngineerId"
            rules={[
              { required: true, message: "Please select factory engineer!" },
            ]}
          >
            <Select placeholder="Factory Engineer" className="custom-input">
              {factoryEngineers?.map((factoryEngineer, index) => (
                <Option key={index} value={factoryEngineer.id}>
                  {factoryEngineer.factoryEngineer}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Work Start Time"
            name="workStartTime"
            rules={[
              { required: true, message: "Please select work start time!" },
            ]}
          >
            <TimePicker
              className="custom-input"
              style={{ width: "100%" }}
              format="HH:mm:ss"
              onChange={(time, timeString) => {
                setWorkStartTime(timeString);
              }}
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Work End Time"
            name="workEndTime"
            rules={[
              { required: true, message: "Please select work end time!" },
            ]}
          >
            <TimePicker
              className="custom-input"
              style={{ width: "100%" }}
              format="HH:mm:ss"
              onChange={(time, timeString) => {
                setWorkEndTime(timeString);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Indent Number"
            name="indentNumber"
            rules={[{ required: true, message: "Please enter indent number!" }]}
          >
            <Input placeholder="Indent Number" className="custom-input" />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Indent Release"
            name="indentRelease"
            rules={[
              { required: true, message: "Please select indent release!" },
            ]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setIndentRelease(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Design Clearance Date"
            name="designClearance"
            rules={[
              { required: true, message: "Please select design clearance!" },
            ]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setDesignClearance(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Account Clearance"
            name="accountClearance"
            rules={[
              { required: true, message: "Please select account clearance!" },
            ]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setAccountClearance(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Shop Documents"
            name="shopDocuments"
            rules={[
              { required: true, message: "Please select shop documents!" },
            ]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setShopDocuments(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Stock Check"
            name="stockCheck"
            rules={[{ required: true, message: "Please select stock check!" }]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setStockCheck(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="PO Prepare"
            name="poPrepare"
            rules={[{ required: true, message: "Please select poprepare!" }]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setPOPrepare(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="PO Approval"
            name="poApproval"
            rules={[{ required: true, message: "Please select poapproval!" }]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setPOApproval(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="PO Release"
            name="poRelease"
            rules={[{ required: true, message: "Please select porelease!" }]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setPORelease(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Job Work Done"
            name="jobWorkDone"
            rules={[
              { required: true, message: "Please select job work done!" },
            ]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setJobWorkDone(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Raw Material Available"
            name="rawMaterialAvailable"
            rules={[
              {
                required: true,
                message: "Please select raw material available!",
              },
            ]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setRawMaterialAvailable(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Other Material Available"
            name="otherMaterialAvailable"
            rules={[
              {
                required: true,
                message: "Please select other material available!",
              },
            ]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setOtherMaterialAvailable(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Paint Material Production"
            name="paintMaterialProduction"
            rules={[
              {
                required: true,
                message: "Please enter paint material production!",
              },
            ]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setPaintMaterialProduction(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Other Material Production"
            name="otherMaterialProduction"
            rules={[
              {
                required: true,
                message: "Please enter other material production!",
              },
            ]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setOtherMaterialProduction(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Panel Production"
            name="panelProduction"
            rules={[
              { required: true, message: "Please select pnael production!" },
            ]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setPanelProduction(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Assembly"
            name="assembly"
            rules={[{ required: true, message: "Please select assembly!" }]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setAssembly(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Cleaning"
            name="cleaning"
            rules={[{ required: true, message: "Please select cleaning!" }]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setCleaning(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Packing"
            name="packing"
            rules={[{ required: true, message: "Please select packing!" }]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setPacking(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} md={12}>
          <Form.Item
            label="Dispatch"
            name="dispatch"
            rules={[{ required: true, message: "Please select dispatch!" }]}
          >
            <DatePicker
              className="custom-input"
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setDispatchDate(dateString);
              }}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button className="default-btn" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NewOrderList;
