import * as api from "../../api";
import {GET_DB_ASSIGN_ORDER} from "../../constants/actionTypes";




export const getAuAssignOrder= () => async (dispatch) => {
    try {
      const { data } = await api.getAuAssignOrder();
      dispatch({ type: GET_DB_ASSIGN_ORDER, payload: data });
    } catch (error) {
      console.log(error);
    }
  };